import OAuthManager from '@services/oauth';
import { get as getApi, registerMonitor } from '@services/api';

const mutations = {
  ACCESS_TOKEN_SET: 'ACCESS_TOKEN_SET',
  REFRESH_TOKEN_SET: 'REFRESH_TOKEN_SET',
  EXPIRES_SET: 'EXPIRES_SET',
};

let oauthManager;
// let refreshInterval;

export default {
  namespaced: true,
  state: {
    accessToken: null,
    refreshToken: null,
    expires: null,
  },
  getters: {
    accessToken: (s) => s.accessToken,
    refreshToken: (s) => s.refreshToken,
    expires: (s) => s.expires,
  },
  mutations: {
    [mutations.ACCESS_TOKEN_SET](state, accessToken) {
      state.accessToken = accessToken;
    },
    [mutations.REFRESH_TOKEN_SET](state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    [mutations.EXPIRES_SET](state, expires) {
      state.expires = expires;
    },
  },
  actions: {
    init({ commit, rootGetters }) {
      const { config } = rootGetters;
      const { id, secret } = config.oauth;
      const api = getApi();
      const url = `${config.url.api}/token`;

      oauthManager = OAuthManager.get({ url });
      oauthManager.setClient(id, secret);

      const { $accessToken, $refreshToken, $expires } = oauthManager;

      commit(mutations.ACCESS_TOKEN_SET, $accessToken);
      commit(mutations.REFRESH_TOKEN_SET, $refreshToken);
      commit(mutations.EXPIRES_SET, $expires);

      // Set header with given access token for automatic authorization via http header
      if ($accessToken) {
        api.setHeader('Authorization', `Bearer ${$accessToken}`);
      }

      // Register custom monitor for API
      registerMonitor(({ status }) => {
        if (status === 401) {
          commit('UNAUTHORIZED', true, { root: true });
        }
      });
    },
  },
};
