<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      />
      <bkf-page
        v-if="isActivePage"
        :loading="false"
        :error="error"
        class="pt-3"
      >
        <bkf-widget>
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0 pt-7"
            >
              <bkf-action-refresh
                @click="refresh"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            v-if="!siteLoading"
            :loading="siteLoading"
            :headers="columns"
            :items="devices"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="id"
            @click:row="handleClick"
          >
            <template #[`item.name`]="{ item }">
              <div>
                {{ item.name }}
                <div class="caption blue-grey--text lighten-1">
                  {{ item.deviceType.name }}
                </div>
              </div>
            </template>
            <template #[`item.deviceGroups.name`]="{ item }">
              <bkf-action-launch
                v-if="item.deviceGroups.length"
                tooltip="device.go-to-device-group"
                @click="redirectToDeviceGroup(item.deviceGroups[0].id)"
              >
                {{ item.deviceGroups[0].name }}
              </bkf-action-launch>
            </template>
            <template #[`item.deviceStatus`]="{ item }">
              <module-activity-indicator
                class="my-2"
                :value="item.deviceStatus.online"
                :last-sync="$deep(item, 'deviceStatus.lastOnline')"
              />
              <enabled-time-indicator
                class="my-2"
                :value="$deep(item, 'stats.activeTime')"
                :is-active="item.deviceStatus.active"
              />
              <work-time-last-active-indicator
                class="my-2"
                :value="$deep(item, 'deviceStatus.working')"
                :last-work="$deep(item, 'deviceStatus.lastWork')"
                :work-time="$deep(item, 'stats.workTime')"
              />
              <operator-last-active-indicator
                v-if="item.deviceStatus.lastWorker"
                :name="$deep(item, 'deviceStatus.lastWorker.name')"
                :active="$deep(item, 'deviceStatus.workerActive')"
                :last-sync="$deep(item, 'deviceStatus.lastWorkerActive')"
              />
              <battery-voltage-indicator
                v-if="$deep(item.deviceStatus, 'battery') !== null"
                class="my-2"
                :value="item.deviceStatus.battery"
                :battery-status="item.deviceStatus.batteryStatus"
              />
              <location-indicator
                v-if="item.deviceStatus.locationStatus !== null"
                :key="item.id"
                :status="item.deviceStatus.locationStatus"
                :device-shown="true"
                :last-sync="item.deviceStatus.deviceLocation !== null
                  ? item.deviceStatus.deviceLocation.time : null"
              />
            </template>
            <template #[`item.motorHours`]="{ item }">
              <motor-hours
                v-if="item.deviceStatus.motorHours"
                :value="item.deviceStatus.motorHours"
              />
            </template>
            <template #[`item.trend`]="{ item }">
              <work-trend-indicator
                :value="item.trend"
              />
            </template>
            <template #[`item.actions`]="{ item }">
              <bkf-action-edit @click="openUpdateDialog(item)" />
              <bkf-dialog-form-simple
                :ref="`deviceUpdateForm${item.id}`"
                :form-url="`device/${item.id}/edit`"
                :action-url="`device/${item.id}`"
                request-method="put"
                submit-text="dialog.save"
                title="device.edit"
              />
            </template>
          </v-data-table>
          <v-progress-circular
            v-if="siteLoading"
            color="primary"
            class="mb-8"
            size="60"
            indeterminate
          />
        </bkf-widget>
      </bkf-page>
      <router-view />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('device.create')"
      />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import debounce from 'lodash/debounce';
import BatteryVoltageIndicator from '@components/domains/device/indicators/BatteryVoltage';
import EnabledTimeIndicator from '@components/domains/device/indicators/EnabledTime';
import ModuleActivityIndicator from '@components/domains/device/indicators/ModuleActivity';
import WorkTimeLastActiveIndicator from '@components/domains/device/indicators/WorkTimeLastActive';
import WorkTrendIndicator from '@components/domains/device/indicators/WorkTrend';
import OperatorLastActiveIndicator from '@components/domains/device/indicators/OperatorLastActive';
import MotorHours from '@components/domains/device/indicators/MotorHours';
import LocationIndicator from '@components/domains/device/indicators/Location';

export default {
  components: {
    BatteryVoltageIndicator,
    EnabledTimeIndicator,
    ModuleActivityIndicator,
    WorkTimeLastActiveIndicator,
    WorkTrendIndicator,
    OperatorLastActiveIndicator,
    MotorHours,
    LocationIndicator,
  },
  mixins: [
    pageMixin,
    listMixin,
  ],
  data() {
    return {
      expanded: [],
      siteLoading: true,
      search: null,
      settings: {
        size: 10,
      },
      footerProps: {
        'items-per-page-options': [5, 10, 25],
      },
      totalItems: 13,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      devices: [],
      columns: [
        {
          value: 'name',
          text: t`device.name`,
          sortable: false,
        },
        {
          value: 'deviceGroups.name',
          text: t`device.groups`,
          sortable: false,
        },
        {
          value: 'deviceStatus',
          text: t`device.status`,
          sortable: false,
        },
        {
          value: 'trend',
          text: t`device.trend`,
          align: 'center',
          sortable: false,
        },
        {
          value: 'motorHours',
          text: t`device.motor-hours`,
          align: 'center',
          sortable: false,
        },
        {
          value: 'actions',
          text: t`table.edit`,
          align: 'right',
          sortable: false,
          filterable: false,
          exportable: false,
        },
      ],
    };
  },
  watch: {
    search: {
      handler() {
        this.pagination.page = 1;
        this.getDataDebounced();
      },
      deep: true,
    },
    pagination: {
      handler(newValue, oldValue) {
        if (oldValue.page !== newValue.page
            || oldValue.itemsPerPage !== newValue.itemsPerPage) {
          // return to first page when sorting has change
          if (oldValue.itemsPerPage !== newValue.itemsPerPage) {
            // eslint-disable-next-line no-param-reassign
            newValue.page = 1;
          }
          this.siteLoading = true;
          this.getDataDebounced();
        }
      },
      deep: true,
    },
  },
  created() {
    this.siteLoading = true;
    this.getDataDebounced = debounce(() => {
      this.getData();
    }, 1000);
  },
  mounted() {
    this.siteLoading = true;
  },
  methods: {
    openUpdateDialog(item) {
      this.$refs[`deviceUpdateForm${item.id}`].open();
    },
    async refresh() {
      this.loading = true;
      await this.getData();
      this.loading = false;
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'device', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
    async getData() {
      this.siteLoading = true;
      const params = {
        page: [this.pagination.page],
        perPage: [this.pagination.itemsPerPage],
        search: [this.search],
        context: 'details',
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `devices?${urlParams}`,
      )
        .then(
          (response) => {
            this.devices = response.data;
            this.totalItems = parseInt(response.headers['x-pager-total-entries'], 10);
            this.siteLoading = false;
          },
        );
    },
    async onActivatePage() {
      this.siteLoading = true;
      await this.initVuex({
        modelName: 'Device',
      });
      await this.getData();
    },
    redirectToDeviceGroup(id) {
      this.$router.push({ name: 'device-group', params: { id } });
    },
  },
};
</script>

<style scoped lang="stylus">
.expand-table
  box-shadow: inset 0 0 0 0 !important;
</style>
