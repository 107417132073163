import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}},[_c(VBtn,{attrs:{"tile":"","color":"primary"},nativeOn:{"click":function($event){return _vm.configurator.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('alert-rule.create'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-6",attrs:{"loading":_vm.loading,"error":_vm.error}},[(_vm.alertRules.length)?_c('bkf-widget',{staticClass:"mt-n2"},[_c(VCardTitle,{staticClass:"px-0"},[_c(VCol,{staticClass:"ml-auto dflex",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.alertRules,"item-class":_vm.rowClass,"items-per-page":_vm.settings.size,"search":_vm.search},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.device.name",fn:function(ref){
var item = ref.item;
return [(item.device)?_c('device-button',{attrs:{"device":item.device}}):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("trans")(("alert-rule.types." + (item.type.split(' ').join('-')))))+"\n          ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VSpacer),_vm._v(" "),_c(VCol,{staticClass:"pr-0"},[_c(VTooltip,{attrs:{"bottom":"","activator":_vm.$refs['switch_' + item.id]}},[_c('span',[_vm._v(_vm._s(_vm.switchTooltip(item)))])]),_vm._v(" "),_c(VSwitch,{ref:'switch_' + item.id,staticClass:"mt-1 w40",attrs:{"dense":"","hide-details":""},on:{"change":function($event){return _vm.setEnabledFlag(item)},"click":function($event){$event.stopPropagation();}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pl-0"},[_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}})],1)],1)]}}],null,true)})],1):_c('bkf-empty-state',{staticClass:"mt-n2 pb-4"})],1):_vm._e(),_vm._v(" "),_c('router-view'),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('alert-rule.creating'),"new-alarm":true}}),_vm._v(" "),_c('bkf-page-settings',{attrs:{"settings":_vm.settings},on:{"update:settings":function($event){_vm.settings=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }