<template>
  <div
    v-if="config.brand.name !== 'rejs'"
    class="iframe-box"
    :class="{ 'iframe-box-mobile': isMobile }"
  >
    <iframe
      :src="src"
    />
  </div>
  <div v-else>
    <div class="page-help">
      <transition
        name="page-switch"
        mode="out-in"
      >
        <bkf-page
          v-if="isActivePage"
          :error="error"
          :loading="loading"
        >
          <bkf-card class="flex-1">
            <bkf-card-content>
              <bkf-accordion :items="myItems" />
            </bkf-card-content>
          </bkf-card>
        </bkf-page>
      </transition>
    </div>
  </div>
</template>

<script>
import LocaleManager from '@services/locale';
import pageMixin from '@mixins/page';
import settingsMixin from '@mixins/misc/settings';
import { mapGetters } from 'vuex';

export default {
  mixins: [
    pageMixin,
    settingsMixin,
  ],
  data() {
    return {
      src: 'https://docs.google.com/document/d/e/2PACX-1vRvt9tCxd1k6zcut5GKkCYZfFlZAmun3EoDefqgZas3DtbV4RmhhgI-MjbHe3aXPiOWgV9BgwJ_6wpc/pub?embedded=true',
      en: 'https://docs.google.com/document/d/e/2PACX-1vRzzL8q5F8PEEXkDJUMb8nhXwuM5RisYFpihpuZLI1lKaJrWDwwhRZD135UJsKiC3oxnZJqgy1gvvgO/pub?embedded=true',
      myItems: [
        {
          title: 'Podsumowanie czasu włączenia urządzeń: co to znaczy że urządzenie jest włączone, a co oznacza że pracujące?',
          value: 'Urządzenie jest włączone w momencie, kiedy moduł monitorujący jest zasilany. Inaczej mówiąc kiedy spawarka jest włączona. Urządzenie pracuje wtedy gdy w ciągu ostatnich 10 minut na spawarce zachodziło jarzenie łuku spawalniczego, czyli faktyczna praca.',
        },
        {
          title: 'W jaki sposób można wyzerować listę alertów?',
          value: 'Na dzień dzisiejszy należy na liście alertów odhaczyć każdy alarm jako zatwierdzony. W przyszłości planujemy dodać hurtowe zatwierdzanie alertów.',
        },
        {
          title: 'Co pokazuje kolor zielony lub czerwony w okienku „Urządzenia wymagające uwagi”?',
          value: 'Kolor zielony pokazywany jest gdy serwer ma informacje o danym parametrze, czerwony gdy nie posiada takich informacji. Moduł jest oznaczony na czerwono gdy jest wyłączony.',
        },
        {
          title: 'Czy moje dane przechowywane w chmurze są bezpieczne?',
          value: 'Dane są bezpieczne w dwojaki sposób. Po pierwsze każdego dnia wykonywana jest kopia bezpieczeństwa danych, dzięki temu niwelujemy ryzyko ich utraty. Po drugie dane przechowywane są na serwerach zabezpieczonych w sposób fizyczny jak i wirtualny przed dostępem osób nieautoryzowanych w bezpiecznym data center.',
        },
        {
          title: 'Co się stanie z danymi w przypadku chwilowego braku sygnału GSM na hali?',
          value: 'Moduł monitorujący zapisuje dane przez okres nawet 2 tygodni i kiedy odzyska sygnał GSM dosyła oczekujące na wysłanie dane.',
        },
        {
          title: 'Czy rejestrator wysyła sygnał jeśli urządzenie jest wyłączone z sieci?',
          value: 'Obecna wersja rejestratora nie posiada takiej możliwości. Jednakże nowsze wersje będą posiadały zapasowe zasilanie bateryjne które będzie potrafiło podtrzymać zasilanie urządzenia nawet przez 36h.',
        },
        {
          title: 'Jak dokładne jest wskazanie lokalizacji urządzenia przez sygnał GSM?',
          value: 'Lokalizacja GPS posiada dokładność do jednego metra. Jednakże jeżeli urządzenia zamknięte są na hali produkcyjnej sygnał GPS może tam nie docierać i wtedy do zlokalizowania urządzenia wykorzystywany jest sygnał AGPS z sieci GSM. Dokładność lokalizacji zależy od gęstości stacji telefonii komórkowej, z reguły jest to dokładność rzędu od kilkudziesięciu do kilkuset metrów.',
        },
        {
          title: 'Czy lokalizacja GSM działa jeśli urządzenie nie jest podłączone do sieci?',
          value: 'Nie. Jednakże nowsze wersje będą posiadały zapasowe zasilanie bateryjne które będzie potrafiło podtrzymać zasilanie urządzenia nawet przez 36h.',
        },
        {
          title: 'Czy rejestracja jest możliwa bez zalogowania się spawacza kartą RFID?',
          value: 'Tak, karta karta i czytnik RFID służa do identyfikacji spawacza i weryfikacji wydajnośći pracy.',
        },
        {
          title: 'Czy jest możliwa analiza czasu pracy kilku operatorów?',
          value: 'Tak, możemy wybrać na zakładce diagnostyki kilku operatorów jednocześnie i porównać ich wydajność pracy.',
        },
        {
          title: 'Co oznacza że rejestrator jest opisany jako „wyłączony” i jest podświetlony na czerwono?',
          value: 'Rejestrator jest wyłączony gdy w ciągu ostatnich 10 minut nie wysyła danych na serwer. Może to oznaczać, że spawarka nie ma zasilania lub że spawarka nie posiada zasięgu GSM.',
        },
        {
          title: 'Jakie znaczenie ma parametr „istotność” w opisie alertów: informacyjnie, ostrzeżenie lub krytyczny?',
          value: 'Oznacza to jak powinien być traktowany przez odbierającego alarm. Możliwe jest również ustawienie z jaką istotnością alarmy są wysyłane automatycznie na adres e-mail. Przykładowo możemy ustawić, że tylko alarmy o znaczeniu krytycznym są wysyłane, a pozostałe są tylko zalogowane w systemie.',
        },
        {
          title: 'Do czego służą alerty?',
          value: 'Informacja dla operatora o przekroczeniu ustawionych parametrów spawania, zmianie lokalizacji urządzenia, braku operacji wykonywanych przez urządzenie.',
        },
        {
          title: 'Alert geolokalizacja',
          value: 'Informacja dla operatora o zmianie położenie urządzenia. Informacja wysyłana jest na pocztę e-mail, a także jest widoczna po zalogowaniu do panelu.',
        },
        {
          title: 'Alert przekroczenia parametrów spawania',
          value: 'Informacja dla operatora o przekroczeniu ustawionego natężenia. Informacja wysyłana jest na pocztę e-mail, a także jest widoczna po zalogowaniu do panelu.',
        },
        {
          title: 'Czy można tworzyć raporty pracy z podziałem na spawaczy?',
          value: 'Spawacz jest identyfikowany za pomocą kart RFID. System rozpoznaje konkretnego spawacza i przypisuje mu pracę urządzania w czasie zalogowania.',
        },
        {
          title: 'Do jakich urządzeń można zamontować panel MOST Rejs?',
          value: 'REJS można zamontować praktycznie w każdym urządzeniu dowolnego producenta.',
        },
        {
          title: 'Jak system MOST Rejs wpływa na spawarkę?',
          value: 'Rejestrator nie ma wpływu na bieżącą pracę spawarki, jedynie zapisuje i wysyła dane związane z jej pracą. Rejestrator nie wymaga oddzielnego włączenia przez spawacza. Rejestrator REJS nie może sterować urządzeniem ani ograniczać jego pracy.',
        },
        {
          title: 'Kto zajmuję się serwisem rejestratora REJS?',
          value: 'Usługi serwisowe świadczy RYWAL-RHC.',
        },
        {
          title: 'Czy rejestrator wymaga okresowej kalibracji?',
          value: 'Nie.',
        },
        {
          title: 'Jaka jest dokładność danych rejestrowanych przez REJS?',
          value: 'Czas spawania monitorowany jest z dokładnością do 3%. Natężenie i napięcie do 5%.',
        },
        {
          title: 'Czy awaria spawarki może uszkodzić rejestrator REJS?',
          value: 'Zależy od typu awarii. Jednakże nie powinna ona uszkodzić prawidłowo zamontowanego rejestratora.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
      config: 'config',
    }),
  },
  created() {
    this.getLanguage();
  },
  methods: {
    async onActivatePage() {
      await this.$sleep(300);
    },
    getLanguage() {
      const localeManager = LocaleManager.get();
      const lang = localeManager.getLanguage();
      this.src = lang !== 'pl' ? this.en : this.src;
    },
  },
};
</script>

<style scoped lang="stylus" src="./Help.styl"></style>
