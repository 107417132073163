<template>
  <bkf-widget
    class="monitor"
    :class="monitorClass"
    :flat="flat"
  >
    <template slot="title">
      <template v-if="showTitle">
        {{ 'diagnostics.title-async-monitor'|trans }}
      </template>
    </template>
    <template slot="actions">
      <template v-if="showActions">
        <template v-if="settings.edited">
          <bkf-action-close @click="remove" />
          <bkf-action-expand
            v-if="!$vuetify.breakpoint.mobile"
            v-model="settings.expanded"
          />
          <bkf-action-refresh @click="refresh" />
          <bkf-action-export
            :exporter="false"
            @click="toCSV"
          />
        </template>
        <bkf-action-options
          @click="onOptions(!settings.edited)"
        />
      </template>
    </template>
    <div :class="{ 'mx-n4': flat }">
      <transition name="fade">
        <div
          v-if="loading"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
      </transition>
      <template v-if="settings.edited">
        <div class="mh-5">
          <template v-if="showDevices">
            <field-devices
              v-model="settings.devices"
              :devices="devices"
            />
          </template>
        </div>
        <div
          class="flex flex-wrap"
        >
          <div class="flex-1 mh-5 wmin-250">
            <field-parameter
              v-model="settings.parameters"
              :label="fieldParameterLabel"
              :disabled="fieldParameterDisabled"
              :parameters="selectableParameters"
            />
          </div>
          <div class="flex-1 mh-5 wmin-250">
            <field-date-range v-model="settings.dateRange" />
          </div>
        </div>
      </template>
      <div class="chart-container">
        <v-alert
          v-if="message"
          type="warning"
          class="mt-0"
        >
          {{ message }}
        </v-alert>
        <v-data-table
          v-if="render"
          dense
          :headers="headers"
          :items="tableData"
          sort-by="date"
          :sort-desc="true"
          item-key="name"
          class="elevation-0"
        />
      </div>
      <bkf-refresher
        :speed="300"
        :pause="loading"
        @refresh="refresh"
      />
    </div>
  </bkf-widget>
</template>

<script>
// Date functions
import format from 'date-fns/format';
import startOfToday from 'date-fns/start_of_today';

// Ramda function
import {
  flatten, findIndex, sortBy, prop, propEq, uniq, toLower, compose,
} from 'ramda';

import csv from '@utils/csv';
import { truthyAll } from '@utils/asserts';

import parameterTrans from '@mixins/misc/parameterTrans';
import { mapActions, mapGetters } from 'vuex';
import FieldDateRange from './fields/DateRange';
import FieldDevices from './fields/Devices';
import FieldParameter from './fields/Parameter';

export default {
  components: {
    FieldDateRange,
    FieldDevices,
    FieldParameter,
  },
  mixins: [
    parameterTrans,
  ],
  props: {
    monitor: {
      required: true,
      type: Object,
    },
    devices: {
      required: true,
      type: Array,
    },
    deviceTypes: {
      required: true,
      type: Array,
    },
    showTitle: {
      required: false,
      type: Boolean,
      default: true,
    },
    showActions: {
      required: false,
      type: Boolean,
      default: true,
    },
    showDevices: {
      required: false,
      type: Boolean,
      default: true,
    },
    expanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    edited: {
      required: false,
      type: Boolean,
      default: true,
    },
    width: {
      required: false,
      type: Number,
      default: null,
    },
    flat: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      render: false,
      loading: false,
      data: [],
      settings: {
        devices: 0,
        parameter: null,
        parameters: [],
        dateRange: [
          startOfToday(),
          startOfToday(),
        ],
        expanded: false,
        edited: true,
      },
      date: {
        format: 'YYYY-MM-DD',
      },
      headers: [
        {
          text: t`diagnostics.time`,
          value: 'date',
        },
        {
          text: t`diagnostics.parameter`,
          value: 'parameter',
        },
        {
          text: t`diagnostics.value`,
          value: 'value',
          sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      menuVisible: 'menu/visible',
    }),
    checkDateRange() {
      const { dateRange } = this.settings;
      const from = format(dateRange[0], 'YYYY-MM-DD');
      const to = format(dateRange[1], 'YYYY-MM-DD');

      if (from < to) {
        return false;
      }

      return true;
    },
    monitorClass() {
      const { settings } = this;

      if (this.$vuetify.breakpoint.mobile || settings.expanded) {
        return ['widget-expanded'];
      }

      return '';
    },
    // Dynamicaly computes selected Devices by mapping devices ids (this.settings.devices)
    // To Devices entries (from this.devices array)
    selectedDevices() {
      const { devices } = this.settings;

      return this.devices.filter((device) => devices === device.id);
    },
    // Flatten and make unique array of parameters ids of all selected Devices
    selectableParameters() {
      const { deviceTypes, selectedDevices } = this;

      const selectedDeviceTypesIds = uniq(selectedDevices.map((device) => device.deviceType.id));
      const selectedDeviceTypes = deviceTypes
        .filter((deviceType) => selectedDeviceTypesIds.includes(deviceType.id));
      const parameters = sortBy(
        compose(toLower, prop('name')),
        uniq(flatten(selectedDeviceTypes.map((deviceType) => deviceType.parameters))),
      );

      return parameters.filter((p) => p.number !== 10000);
    },
    // Find work parameter in selectable parameters
    selectableWorkParameter() {
      const { selectableParameters } = this;
      const tagName = 'effective work time';

      return selectableParameters.find((p) => p.tags.includes(tagName)) || null;
    },
    selectableActiveParameter() {
      const { selectableParameters } = this;
      const tagName = 'device active';

      return selectableParameters.find((p) => p.tags.includes(tagName)) || null;
    },
    // Computes if Parameter field should be disabled
    fieldParameterDisabled() {
      return this.selectedDevices.length === 0;
    },
    // Computes label for Parameter field
    fieldParameterLabel() {
      if (this.selectedDevices.length) {
        return t`monitor.parameter`;
      }

      return t`monitor.select-device-at-first`;
    },
    // Settings validation state
    settingsValid() {
      const { selectedDevices } = this;
      const { dateRange, parameters } = this.settings;

      return truthyAll(
        dateRange[0],
        dateRange[1],
        parameters.length,
        selectedDevices.length,
      );
    },
    message() {
      const { settings } = this;
      if (!settings.devices) {
        return t`monitor.message.no-devices`;
      }

      if (!this.selectableParameters.length) {
        if (this.selectedDevices.length > 1) {
          return t`monitor.message.no-common-parameters`;
        }

        return t`monitor.message.no-parameters`;
      }

      if (this.selectableParameters.length && !settings.parameters.length) {
        return t`monitor.message.no-parameter`;
      }

      return '';
    },
    tableData() {
      const { data } = this;
      const arr = [];

      data.forEach((payload) => {
        payload.parameters.forEach((parameter) => {
          parameter.categories.forEach((category, index) => {
            let value = parameter.values[index];

            if (!Number.isInteger(value)) {
              value = Number(value).toFixed(2);
            }

            arr.push({
              date: category,
              parameter: parameter.name,
              value: `${value} [${parameter.unit.short}]`,
            });
          });
        });
      });

      return arr;
    },
    ...mapActions({
      toggleMenu: 'menu/toggle',
      showMenu: 'menu/show',
      hideMenu: 'menu/hide',
    }),
  },
  watch: {
    // Watch paramsState save settings when parameters are valid
    settings: {
      deep: true,
      handler(to, from) {
        const { selectableParameters } = this;

        // Check if parameter is selectable
        if (to.parameter && !selectableParameters.includes(to.parameter)) {
          this.settings.parameter = null;
          return;
        }

        // Save settings and resize chart
        this.saveSettings();

        // Edit or expand mode was changed so not fetch Data
        if (to.edited !== from.edited || to.expanded !== from.expanded) {
          return;
        }

        if (this.settingsValid) {
          this.fetchData();
        } else {
          this.render = false;
        }
      },
    },
    async selectableParameters() {
      const { selectableWorkParameter, selectableParameters, settings } = this;
      const { parameter } = settings;

      settings.parameters = settings.parameters.filter((param) => findIndex(propEq('number', param))(selectableParameters) !== -1);

      if (parameter && selectableParameters.includes(parameter)) {
        return;
      }

      if (selectableWorkParameter && settings.parameters.length === 0) {
        settings.parameters = [selectableWorkParameter.number];
        return;
      }

      if (selectableParameters.length) {
        [settings.parameter] = selectableParameters;
      }
    },
  },
  mounted() {
    const {
      edited, expanded, devices, parameters,
    } = this.monitor.params || {
      devices: [],
      interval: 'hour',
      parameters: [],
      edited: this.edited,
      expanded: this.expanded,
    };

    if (devices !== null) {
      this.settings.devices = devices;
    }

    if (parameters.length) {
      this.settings.parameters = parameters;
    }

    this.settings.edited = edited;
    this.settings.expanded = expanded;
    this.onOptions(edited);
  },
  methods: {
    async fetchData() {
      this.render = false;
      const {
        date, settings, selectedDevices,
      } = this;
      const { dateRange } = settings;
      const devices = selectedDevices.map((device) => device.id);

      const from = format(dateRange[0], date.format);
      const to = format(dateRange[1], date.format);
      const url = '/devices/diagnostics/async/multiple';

      this.loading = true;

      const response = await this.$http.get(url, {
        devices: devices.join(','),
        parameterNumber: settings.parameters.join(','),
        from,
        to,
      });

      this.loading = false;

      if (response.status === 404) {
        settings.parameters = [];
      }

      const { data } = response;

      this.data = data.data;

      if (data.data.length) {
        this.render = true;
      } else {
        this.render = false;
      }
    },
    remove() {
      this.$emit('remove', this.monitor);
    },
    onOptions(edited) {
      this.settings.edited = edited;
    },
    async refresh() {
      if (this.settingsValid) {
        await this.fetchData();
      }
    },
    toCSV() {
      const labels = ['date', 'parameter', 'value'];
      const { tableData } = this;
      const rows = tableData.map((row) => [row.date, row.parameter, row.value]);

      csv(rows, labels);
    },
    saveSettings() {
      const { monitor, settings } = this;
      const {
        devices, parameters, expanded, edited,
      } = settings;

      monitor.params = {
        devices,
        parameters,
        edited,
        expanded,
      };

      this.$emit('save');
    },
  },
};
</script>

<style scoped lang="stylus">
.progress
  z-index: 10
  position: absolute
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
</style>
