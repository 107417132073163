<template>
  <v-row>
    <v-col cols="3">
      <img
        v-if="showAvatar"
        class="avatar-preview"
        :src="showAvatar"
      >
      <v-icon
        v-else
        class="default_avatar"
      >
        mdi-account
      </v-icon>
    </v-col>
    <v-col>
      <v-form
        ref="form"
      >
        <v-file-input
          :label="$trans('profile.settings.avatar')"
          accept="image/png, image/jpeg"
          prepend-icon="mdi-camera"
          change="getAvatar($event)"
        />
        <v-text-field
          v-model="user.firstname"
          :label="$trans('profile.settings.first-name')"
          required
          :error-messages="getInputValidation('firstname')"
        />
        <v-text-field
          v-model="user.lastname"
          :label="$trans('profile.settings.last-name')"
          required
          :error-messages="getInputValidation('lastname')"
        />
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatar: null,
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      user: 'users/current',
    }),
    showAvatar() {
      return this.avatar || this.user.avatar;
    },
  },
  watch: {
    validation() {
      this.errors = this.validation;
    },
  },
  mounted() {
    this.errors = this.validation;
  },
  methods: {
    getInputValidation(inputName) {
      if (JSON.stringify(this.errors) === '{}') {
        return [];
      }

      if (!(inputName in this.errors)) {
        return [];
      }

      return [this.$trans(this.errors[inputName].msg)];
    },
    getAvatar(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.avatar = reader.result;
        this.profileChanged();
      };
    },
    profileChanged() {
      this.$emit('change', { avatar: this.avatar });
    },
  },
};
</script>

<style scoped lang="stylus" src="./ProfileSettings.styl"></style>
