<template>
  <div class="page-device-operators-report">
    <transition
      name="page-switch"
      mode="out-in"
    >
      <bkf-page
        :loading="false"
        class="pt-3"
      >
        <bkf-widget>
          <v-row>
            <v-col v-if="showDeviceOperatorsGroups">
              <field-device-operators-group
                v-model="settings.deviceOperatorsGroup"
                :device-operators-group="deviceOperatorsGroup"
              />
            </v-col>
            <v-col v-if="showDeviceGroups">
              <field-device-groups
                v-model="settings.deviceGroups"
                :device-groups="deviceGroups"
              />
            </v-col>
            <v-col>
              <field-date-range
                v-model="settings.date.range"
              />
            </v-col>

            <v-col cols="auto">
              <v-btn
                :disabled="!settingsValid || fetching"
                tile
                color="primary"
                class="mt-2"
                @click="generateFile"
              >
                {{ 'report.action.export'|trans }}
              </v-btn>
            </v-col>
          </v-row>
          <div
            v-if="message"
            class="message"
          >
            <v-alert
              type="warning"
              class="mt-0"
            >
              {{ message|trans }}
            </v-alert>
          </div>
          <div class="report">
            <v-tabs
              v-if="report.length && !fetching"
              v-model="tab"
              show-arrows
            >
              <v-tabs-slider />
              <v-tab
                v-for="(group, groupindex) in report"
                :key="groupindex"
              >
                {{ group.name }}
              </v-tab>
              <v-tabs-items
                v-model="tab"
              >
                <v-tab-item
                  v-for="(group, groupindex) in report"
                  :key="groupindex"
                >
                  <slot
                    v-if="group.items.length > 0"
                    name="groupItemTable"
                    :items="group.items"
                    :groupindex="groupindex"
                  >
                    <v-simple-table>
                      <template #default>
                        <thead>
                          <tr>
                            <th> id </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(itemReprt, index) in group.items"
                            :key="`${reportType}-${groupindex}-${index}`"
                          >
                            <td>{{ itemReprt.id }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </slot>
                  <span
                    v-else
                    class="text-sm-center"
                  >
                    <h5
                      class="py-7"
                    >
                      {{ 'widget.lack-of-data'|trans }}
                    </h5>
                  </span>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </div>
          <span v-if="fetching">
            <v-progress-circular
              color="primary"
              class="mb-8"
              size="60"
              indeterminate
            />
          </span>
        </bkf-widget>
      </bkf-page>
    </transition>
  </div>
</template>

<script>
import { startOfToday, subDays, format } from 'date-fns';
import toExportMixin from '@mixins/export';
import { mapGetters } from 'vuex';
import FieldDateRange from './fields/DateRange';
import FieldDeviceGroups from './fields/DeviceGroups';
import FieldDeviceOperatorsGroup from './fields/DeviceOperatorsGroup';

export default {
  components: {
    FieldDateRange,
    FieldDeviceGroups,
    FieldDeviceOperatorsGroup,
  },
  mixins: [
    toExportMixin,
  ],
  props: {
    item: {
      type: String,
      default: 'Default Title',
    },
    groupType: {
      required: true,
      type: String,
    },
    reportType: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      fetching: false,
      fetched: false,
      tab: 0,
      report: [],
      deviceGroups: [],
      deviceOperatorsGroup: [],
      settings: {
        deviceGroups: [],
        deviceOperatorsGroup: [],
        date: {
          format: 'YYYY-MM-DD',
          range: [
            subDays(startOfToday(), 31),
            startOfToday(),
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
    showDeviceOperatorsGroups() {
      if (this.groupType === 'deviceOperatorGroup') {
        return true;
      }

      return false;
    },
    showDeviceGroups() {
      if (this.groupType === 'deviceGroup') {
        return true;
      }

      return false;
    },
    settingsValid() {
      const { deviceGroups, deviceOperatorsGroup } = this.settings;

      if (this.showDeviceGroups && deviceGroups.length === 0) {
        return false;
      }

      if (this.showDeviceOperatorsGroups && deviceOperatorsGroup.length === 0) {
        return false;
      }

      return true;
    },
    message() {
      const { deviceGroups, deviceOperatorsGroup } = this.settings;

      if (this.showDeviceGroups && deviceGroups.length === 0) {
        return 'report.message.no-device-groups-selected';
      }

      if (this.showDeviceOperatorsGroups && deviceOperatorsGroup.length === 0) {
        return 'report.message.no-device-operators-groups-selected';
      }

      if (this.fetched && this.report.length === 0) {
        return 'report.message.no-data-found';
      }

      return null;
    },
  },
  watch: {
    settings: {
      deep: true,
      async handler(val) {
        this.fetchData(val);
      },
    },
  },
  mounted() {
    this.onActivatePage();
  },
  methods: {
    async onActivatePage() {
      const DeviceGroup = await this.$model('DeviceGroup');
      const { entries: deviceGroups } = await DeviceGroup.query({
        sort: { name: 'asc' },
      });
      this.deviceGroups = deviceGroups;

      const DeviceOperatorGroup = await this.$model('DeviceOperatorGroup');
      const { entries } = await DeviceOperatorGroup.query({
        sort: { name: 'asc' },
      });

      this.deviceOperatorsGroup = entries.sort(
        (a, b) => a.name.localeCompare(b.name, 'pl'),
      );
    },
    async fetchData(settings) {
      if (settings !== null
        && settings.deviceGroups.length === 0
        && settings.deviceOperatorsGroup.length === 0
      ) {
        this.report = [];
        return;
      }

      const { date: dateRange } = this.settings;
      const url = '/reports/factory/data';

      this.fetching = true;
      this.fetched = false;

      this.$http.get(url, {
        from: format(dateRange.range[0], dateRange.format),
        to: format(dateRange.range[1], dateRange.format),
        groups: this.getGroups(),
        type: this.reportType,
      })
        .then(
          (response) => {
            if (response.status === 200) {
              this.report = response.data.data;
              this.fetched = true;
            }
            this.fetching = false;
          },
        );
    },
    getDeviceGroups() {
      return this.settings.deviceGroups.map((o) => o.id).join(',');
    },
    getDeviceOperatorsGroup() {
      return this.settings.deviceOperatorsGroup.map((o) => o.id).join(',');
    },
    getGroups() {
      if (this.showDeviceGroups) {
        return this.getDeviceGroups();
      }

      if (this.showDeviceOperatorsGroups) {
        return this.getDeviceOperatorsGroup();
      }

      return null;
    },
    async generateFile() {
      const { date: dateRange } = this.settings;
      this.fetched = false;

      const params = {
        from: format(dateRange.range[0], dateRange.format),
        to: format(dateRange.range[1], dateRange.format),
        groups: this.getGroups(),
        type: this.reportType,
      };

      await this.onExport({
        url: '/reports/factory/xlsx',
        filetype: ' application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        filename: `${this.reportType}-${format(dateRange.range[0], dateRange.format)}-${format(dateRange.range[1], dateRange.format)}`,
        params,
      });

      this.fetched = true;
    },
  },
};
</script>
