<template>
  <v-row>
    <v-col>
      <bkf-page
        v-if="isActivePage"
        :error="error"
        :loading="loading"
        class="pt-3"
      >
        <div>
          <bkf-floating-button-add
            v-if="isTablet"
            :tooltip="$trans('report.add')"
            @click.native="configurator"
          />
          <predefined-reports :reports="reportsType" />
          <report-configurator
            :reports-type="reportsType"
            :models="model"
            :reports-configurations="reportsConfigurations"
          />
        </div>
      </bkf-page>
      <router-view />
      <bkf-crud
        v-if="isTablet"
        ref="crud"
        :create-submit-label="$trans('report.action.create')"
        :update-title="$trans('report.action.edit')"
        :delete-title="$trans('report.action.delete')"
        :reports-type="reportsType"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import PredefinedReports from './Predefined';
import ReportConfigurator from './Configurator';

export default {
  components: {
    PredefinedReports,
    ReportConfigurator,
  },
  mixins: [
    pageMixin,
    settingsMixin,
    listMixin,
  ],
  data() {
    return {
      reportsType: [
        {
          name: 'efficiency-report',
          title: t`report.list.efficiency-report.title`,
          description: t`report.list.efficiency-report.description`,
        },
        {
          name: 'device-operators-report',
          title: t`report.list.device-operators-report.title`,
          description: t`report.list.device-operators-report.description`,
        },
        {
          name: 'device-work-details-report',
          title: t`report.list.device-work-details-report.title`,
          description: t`report.list.device-work-details-report.description`,
        },
        {
          name: 'device-work-device-shift',
          title: t`report.list.device-work-device-shift.title`,
          description: t`report.list.device-work-device-shift.description`,
        },
        {
          name: 'device-work-shift-chart',
          title: t`report.list.device-work-shift-chart.title`,
          description: t`report.list.device-work-shift-chart.description`,
        },
      ],
      model: null,
      refs: this.$refs,
      reportsConfigurations: [],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
  },
  methods: {
    async onActivatePage() {
      await this.init({
        modelName: 'ReportsConfiguration',
      });
    },
  },
};
</script>
