import {
  camelize,
  dasherize,
  pluralize,
  underscore,
} from 'inflection';

import { pipe } from 'ramda';

// Http response statuses as errors
const errors = {
  404: 'not-found',
  default: 'internal-error',
};

/**
 * Convert status to error
 * @param {Number} status Status
 * @returns {String} Error
 */
const statusToError = (status) => errors[status] || errors.default;

/**
 * Convert error to message
 * @param {String} error Error
 * @returns {String} Message
 */
const errorToMessage = (error) => t`form.error.${error}`;

/**
 * Get translated message by status
 * @param {Number} status Status
 * @returns {String} Translated message
 */
const message = pipe(errorToMessage, statusToError);

export default {
  data() {
    return {
      // Selected entry
      selected: null,
      // Total entries
      entries: [],
      // Model
      model: null,
    };
  },
  mounted() {
    this.bindDialogEvents();
  },
  methods: {
    /**
     * Initialize list
     * @param {string} payload Settings
     */
    async init(payload) {
      const { modelName, queryParams } = payload;
      this.model = await this.$model(modelName);
      const name = this.model.$name;
      const pluralized = camelize(pluralize(name), true);
      const context = `${pluralize(name).toLowerCase()}_list`;

      const defaultParams = {
        contexts: [context],
        sort: { name: 'asc' },
      };

      const { entries } = await this.model.query(queryParams || defaultParams);
      this.entries = entries;
      this[pluralized] = entries.map((entry) => entry.$data);
    },
    /**
     * Initialize with no data
     * @param {string} payload Settings
     */
    async initVuex(payload) {
      const { modelName } = payload;
      this.model = await this.$model(modelName);
    },
    /**
     * Bind dialog events
     */
    bindDialogEvents() {
      const { crud } = this.$refs;

      if (!crud) {
        return;
      }

      // explicitly bind events on createDialog
      if (crud.createDialogRef) {
        crud.createDialogRef.$on('created', (payload) => this.created(payload));
        crud.createDialogRef.$on('createError', (payload) => this.createError(payload));
        crud.createDialogRef.$on('fetchError', (payload) => this.fetchError(payload));
        crud.createDialogRef.$on('validateError', (payload) => this.validateError(payload));
      }

      // explicitly bind events on updateDialog
      if (crud.updateDialogRef) {
        crud.updateDialogRef.$on('updated', (payload) => this.updated(payload));
        crud.updateDialogRef.$on('updateError', (payload) => this.updateError(payload));
        crud.updateDialogRef.$on('fetchError', (payload) => this.fetchError(payload));
        crud.updateDialogRef.$on('validateError', (payload) => this.validateError(payload));
      }

      // explicitly bind events on deleteDialog
      if (crud.deleteDialogRef) {
        crud.deleteDialogRef.$on('deleted', (payload) => this.deleted(payload));
        crud.deleteDialogRef.$on('deleteError', (payload) => this.deleteError(payload));
        crud.deleteDialogRef.$on('fetchError', (payload) => this.fetchError(payload));
      }

      // explicitly bind events on createDialog
      if (crud.configuratorDialogRef) {
        crud.configuratorDialogRef.$on('created', (payload) => this.created(payload));
        crud.configuratorDialogRef.$on('createError', (payload) => this.createError(payload));
        crud.configuratorDialogRef.$on('fetchError', (payload) => this.fetchError(payload));
        crud.configuratorDialogRef.$on('validateError', (payload) => this.validateError(payload));
      }
    },
    /**
     * Redirect to defails Page of entry
     * @param {{}} row
     */
    redirect({ id }) {
      const name = this.model.$name;
      this.$router.push({ name: dasherize(underscore(name)), params: { id } });
    },
    /**
     * Find Entry with given id
     * @param {number} id
     * @return {{}} Entry
     */
    find(id) {
      return this.entries.find((entry) => entry.id === id);
    },
    /**
     * Create - open dialog
     */
    create() {
      this.$refs.crud.create(this.model);
    },

    /**
     * Configurator - open dialog
     */
    configurator() {
      this.$refs.crud.configurator(this.model);
    },
    /**
     * Edit - open dialog
     * @param {{}} row
     */
    async edit({ id }) {
      const entry = await this.model.get(id);
      this.selected = entry;
      this.$refs.crud.update(entry);
    },
    /**
     * Remove - open dialog
     * @param {{}} row
     */
    async remove({ id }) {
      const entry = await this.model.get(id);
      this.selected = entry;
      this.$refs.crud.delete(entry);
    },
    /**
     * Created entry
     * @param {{}} data
     */
    async created({ response }) {
      const name = this.model.$name;

      if (name === 'Device') {
        this.$notify.success(t`${dasherize(underscore(name))}.created`);
        return;
      }

      if (name === 'DeviceOperator') {
        return;
      }

      const pluralized = camelize(pluralize(name), true);
      const context = `${pluralize(name).toLowerCase()}_list`;
      this.$notify.success(t`${dasherize(underscore(name))}.created`);
      const entry = await this.model.get({
        id: response.data.id,
        contexts: [context],
      });
      this.entries.push(entry);
      this[pluralized].unshift(entry.$data);
    },
    /**
     * Updated entry
     */
    async updated({ entry }) {
      this.$notify.success(t`${dasherize(underscore(entry.$model.$name))}.edited`);
      await entry.sync();
    },
    /**
     * Deleted entry
     */
    deleted({ entry }) {
      const name = entry.$model.$name;
      const pluralized = camelize(pluralize(name), true);
      const index = this[pluralized].indexOf(entry.$data);
      this[pluralized].splice(index, 1);
      this.selected = null;
      this.$notify.success(t`${dasherize(underscore(name))}.deleted`);
    },
    fetchError({ response }) {
      this.$notify.error(message(response.status));
    },
    validateError() {
      // for later use
    },
    createError({ response }) {
      this.$notify.error(message(response.status));
    },
    updateError({ response }) {
      this.$notify.error(message(response.status));
    },
    deleteError({ response }) {
      this.$notify.error(message(response.status));
    },
  },
};
