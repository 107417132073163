<template>
  <bkf-widget
    class="monitor"
    :class="monitorClass"
    :flat="flat"
  >
    <template slot="title">
      <template v-if="showTitle">
        {{ 'diagnostics.title-work-activity-shift'|trans }}
      </template>
    </template>
    <template slot="actions">
      <template v-if="showActions">
        <template v-if="settings.edited">
          <bkf-action-close @click="remove" />
          <bkf-action-expand
            v-if="!$vuetify.breakpoint.mobile"
            v-model="settings.expanded"
            @click="resize"
          />
          <bkf-action-refresh @click="refresh" />
        </template>
        <bkf-action-options
          @click="onOptions(!settings.edited)"
        />
      </template>
    </template>
    <div :class="{ 'mx-n4': flat }">
      <transition name="fade">
        <div
          v-if="loading"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
      </transition>
      <template v-if="settings.edited">
        <div class="mh-5">
          <template v-if="showDevices">
            <div
              class="flex flex-justify-start"
            >
              <v-radio-group
                v-model="settings.byGroups"
                :mandatory="true"
                :hide-details="true"
                row
                dense
                class="mb-4 mt-2"
              >
                <v-radio
                  :label="$trans('monitor.devices')"
                  :value="false"
                />
                <v-radio
                  :label="$trans('monitor.device-groups')"
                  :value="true"
                />
              </v-radio-group>
              <div class="flex flex-justify-end">
                <v-radio-group
                  v-model="settings.parameter"
                  :mandatory="true"
                  :hide-details="true"
                  row
                  dense
                  class="mb-4 mt-2"
                >
                  <v-radio
                    :label="$trans('diagnostics.work')"
                    value="work"
                  />
                  <v-radio
                    :label="$trans('diagnostics.active')"
                    value="active"
                  />
                  <v-radio
                    :label="$trans('diagnostics.counter')"
                    value="counter"
                  />
                </v-radio-group>
              </div>
            </div>
            <field-devices
              v-show="!settings.byGroups"
              v-model="settings.devices"
              :devices="devices"
            />
            <field-device-groups
              v-show="settings.byGroups"
              v-model="settings.deviceGroups"
              :device-groups="deviceGroups"
            />
          </template>
        </div>
        <div
          class="flex flex-wrap"
        >
          <!--<div class="flex-1 mh-5 wmin-250">
          <v-autocomplete
            v-model="settings.parameter"
            :items="selectableParameters"
            :label="$trans('monitor.parameter')"
            item-text="text"
            item-value="id"
          />
          </div> -->
          <div class="flex-1 mh-5 wmin-250">
            <field-date-range v-model="settings.dateRange" />
          </div>
          <div class="flex-1 mh-5 wmin-250">
            <field-interval
              v-if="false"
              v-model="settings.interval"
              :shifts="shifts"
              :check-date-range="checkDateRange"
            />
          </div>
          <div class="flex-1 mh-5 wmin-250">
            <v-autocomplete
              v-if="false"
              :disabled="false"
              :value="settings.type"
              :items="selectableTypes"
              :label="$trans('monitor.type')"
              item-text="text"
              item-value="report_type"
            />
          </div>
        </div>
      </template>
      <div class="chart-container">
        <v-alert
          v-if="message"
          type="warning"
          class="mt-0"
        >
          {{ message }}
        </v-alert>
        <chart
          v-if="settingsValid && render"
          ref="chart2"
          :options="echart"
          :auto-resize="true"
        />
      </div>
      <bkf-refresher
        :speed="300"
        :pause="loading"
        @refresh="refresh"
      />
    </div>
  </bkf-widget>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts.vue';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/axisPointer';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/toolbox';

// Date functions
import format from 'date-fns/format';
import subDays from 'date-fns/sub_days';
import startOfDay from 'date-fns/start_of_day';

// Ramda function
import {
  flatten, uniq,
} from 'ramda';
import toExportMixin from '@mixins/export';
// import csv from '@utils/csv';
import { truthyAll } from '@utils/asserts';
// import human from '@utils/human';
import { mapActions, mapGetters } from 'vuex';

import parameterTrans from '@mixins/misc/parameterTrans';
import FieldDateRange from './fields/DateRange';
import FieldDevices from './fields/Devices';
import FieldDeviceGroups from './fields/DeviceGroups';
import FieldInterval from './fields/Interval';

// import colors, { swatches } from './internals/colors';
import colors from './internals/colors';
import intervals from './internals/intervals';
import echartsConfig from './internals/echarts';

export default {
  components: {
    chart: ECharts,
    FieldDateRange,
    FieldDevices,
    FieldDeviceGroups,
    FieldInterval,
  },
  mixins: [
    parameterTrans,
    echartsConfig,
    toExportMixin,
  ],
  props: {
    monitor: {
      required: true,
      type: Object,
    },
    devices: {
      required: true,
      type: Array,
    },
    deviceGroups: {
      required: true,
      type: Array,
    },
    shifts: {
      required: false,
      type: Array,
      default: () => [],
    },
    showTitle: {
      required: false,
      type: Boolean,
      default: true,
    },
    showActions: {
      required: false,
      type: Boolean,
      default: true,
    },
    showDevices: {
      required: false,
      type: Boolean,
      default: true,
    },
    expanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    edited: {
      required: false,
      type: Boolean,
      default: true,
    },
    width: {
      required: false,
      type: Number,
      default: null,
    },
    flat: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      render: true,
      loading: false,
      selectableTypes: [
        { report_type: 'device-work-device-chart', text: 'device' },
        { report_type: 'device-work-operator-chart', text: 'operator' },
        { report_type: 'device-work-shift-chart', text: 'shift' },
      ],
      selectableParameters: [
        { id: 'workSum', text: t`diagnostics.work` },
        { id: 'activeSum', text: t`diagnostics.active` },
        { id: 'counterSum', text: t`diagnostics.counter` },
      ],
      settings: {
        type: null,
        devices: [],
        deviceGroups: [],
        parameter: 'work',
        interval: intervals[0],
        dateRange: [
          startOfDay(subDays(new Date(), 7)),
          startOfDay(new Date()),
        ],
        expanded: false,
        edited: true,
        byGroups: false,
      },
      unit: {
        short: null,
        long: null,
        original_short: null,
        original_long: null,
        min: null,
        max: null,
        shift: t`shifts.shift`,
      },
      units: [],
      date: {
        format: 'YYYY-MM-DD',
      },
      echart: {
        grid: {
          top: 80,
          right: 90,
          left: 60,
          bottom: 20,
          containLabel: true,
        },
        toolbox: {
          show: true,
          itemGap: 10,
          itemSize: 20,
          feature: {
            restore: {
              title: t`chart.restore`,
            },
            dataZoom: {
              title: {
                zoom: t`chart.area-zooming`,
                back: t`chart.restore-area-zooming`,
              },
              yAxisIndex: 'none',
            },
            saveAsImage: {
              title: t`chart.save-as-image`,
            },
            mySaveAsCSV: {
              show: true,
              title: t`chart.save-as-xlsx`,
              icon: 'path://M0,0h10l4,4v16h-14v-20M10,0v4h4M4,8l6,8m0,-8l-6,8',
              onclick: this.toXLSX,
            },
          },
        },
        legend: {
          data: [],
          top: 40,
          type: 'scroll',
        },
        tooltip: {
          confine: true,
        },
        xAxis: {
          data: [],
          type: 'category',
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
            show: true,
          },
          splitLine: {
            show: false,
          },
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
        }],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value) => `${value}  min`,
          },
          axisLine: {
            show: false,
          },
        },
        series: [],
        animationDuration: 300,
        color: colors,
        magicType: {
          type: ['bar'],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      menuVisible: 'menu/visible',
    }),
    checkDateRange() {
      const { dateRange } = this.settings;
      const from = format(dateRange[0], 'YYYY-MM-DD');
      const to = format(dateRange[1], 'YYYY-MM-DD');

      if (from < to) {
        return false;
      }

      return true;
    },
    monitorClass() {
      const { settings } = this;

      if (this.$vuetify.breakpoint.mobile || settings.expanded) {
        return ['widget-expanded'];
      }

      return '';
    },
    selectedDevices() {
      const { devices, deviceGroups, byGroups } = this.settings;

      if (byGroups) {
        const results = this.deviceGroups
          .filter((deviceGroup) => deviceGroups.includes(deviceGroup.id))
          .map((deviceGroup) => deviceGroup.devices.map((device) => device.id));
        const filtered = uniq(flatten(results));

        return this.devices.filter((device) => filtered.includes(device.id));
      }

      return this.devices.filter((device) => devices.includes(device.id));
    },
    // Settings validation state
    settingsValid() {
      const { shifts } = this;
      const {
        devices,
        interval,
        dateRange,
        deviceGroups,
        byGroups,
        parameter,
      } = this.settings;

      if (interval && interval.id === 'shifts' && shifts.length === 0) {
        return false;
      }

      if (!devices.length && !byGroups) {
        return false;
      }

      if (!deviceGroups.length && byGroups) {
        return false;
      }

      if (parameter === null) {
        return false;
      }

      return truthyAll(
        dateRange[0],
        dateRange[1],
        interval,
      );
    },
    message() {
      const {
        devices,
        interval,
        deviceGroups,
        byGroups,
        parameter,
      } = this.settings;

      if (!devices.length && !byGroups) {
        return t`monitor.message.no-devices`;
      }

      if (!deviceGroups.length && byGroups) {
        return t`monitor.message.no-device-groups`;
      }

      if (parameter == null) {
        return t`monitor.message.no-parameter`;
      }

      if (interval && interval.id === 'shifts' && !this.shifts.length) {
        return t`monitor.message.no-shifts-defined`;
      }

      return '';
    },
  },
  watch: {
    // Watch paramsState save settings when parameters are valid
    settings: {
      deep: true,
      handler(to, from) {
        // Check if device select mode was changed
        if (to.byGroups !== from.byGroups) {
          this.saveSettings();
          return;
        }

        // Save settings and resize chart
        this.saveSettings();
        this.resize();

        // Edit or expand mode was changed so not fetch Data
        if (to.edited !== from.edited || to.expanded !== from.expanded) {
          return;
        }

        if (this.settingsValid) {
          this.fetchData();
        }
      },
    },
    // Watch width value (if passed) and if its changed, then resize chart
    width(value) {
      this.resize(value);
    },
    // Resize if navigation menu is toggled
    menuVisible() {
      this.resize();
    },
  },
  mounted() {
    const {
      edited, expanded, devices, deviceGroups,
      byGroups, interval, magicType,
    } = this.monitor.params || {
      devices: [],
      deviceGroups: [],
      byGroups: false,
      interval: '5min',
      parameter: 'work',
      edited: this.edited,
      expanded: this.expanded,
      magicType: 'line',
    };

    if (devices.length) {
      this.settings.devices = this.devices
        .filter((device) => devices.includes(device.id))
        .map((device) => device.id);
    }

    if (byGroups) {
      this.settings.byGroups = byGroups;
    }

    if (deviceGroups.length) {
      this.settings.deviceGroups = this.deviceGroups
        .filter((deviceGroup) => deviceGroups.includes(deviceGroup.id))
        .map((deviceGroup) => deviceGroup.id);
    }

    if (interval) {
      this.settings.interval = intervals.find((i) => i.id === interval);
    }

    this.settings.edited = edited;
    this.settings.expanded = expanded;
    this.settings.magicType = magicType;
    this.onOptions(edited);
  },
  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggle',
      showMenu: 'menu/show',
      hideMenu: 'menu/hide',
    }),
    onMagicTypeChange(value) {
      const { currentType } = value;
      this.settings.magicType = currentType;
      this.saveSettings();
    },
    async fetchData() {
      this.loading = true;
      this.render = false;
      const {
        echart, date, settings,
      } = this;
      // const {
      //   interval,
      //   dateRange,
      //   byGroups,
      //   parameter,
      //   devices,
      //   deviceGroups,
      // } = settings;

      const {
        dateRange,
        byGroups,
        parameter,
        devices,
        deviceGroups,
      } = settings;

      let parameterNew = parameter;

      if (parameter === 'work') {
        parameterNew = 'workSum';
      }

      if (parameter === 'active') {
        parameterNew = 'activeSum';
      }

      if (parameter === 'counter') {
        parameterNew = 'counterSum';
      }

      const from = format(dateRange[0], date.format);
      const to = format(dateRange[1], date.format);

      let newData = null;

      const urlNew = '/reports/factory/data';

      const requestParameters = {
        from,
        to,
        // interval: interval.id,
        type: 'device-work-shift-chart',
      };

      if (byGroups) {
        requestParameters.groups = deviceGroups.join(',');
      } else {
        requestParameters.devices = devices.join(',');
      }

      const { status, data } = await this.$http.get(urlNew, requestParameters);

      if (status === 200) {
        newData = data;
      }

      this.units = [];
      // Create categories

      const categories = [];
      const categoriesFormated = [];
      // get startTime categories
      newData.data.forEach((item) => {
        if (item.name === 'summary') {
          item.items.forEach((subItem) => {
            // const dateObj = new Date(subItem.name);
            // const convertedDateString = format(dateObj, 'YYYY-MM-DD HH:mm');
            if (!categories.includes(subItem.name)) {
              categories.push(subItem.name);
              categoriesFormated.push(subItem.name);
            }
          });
        }
      });

      // console.log('categories', categories);

      this.echart.series = [];
      this.echart.legend.data = [];

      // console.log('parameterNew', parameterNew);

      newData.data.forEach((payload, index) => {
        const { items, name } = payload;

        if (name === 'summary') {
          const chartData = [];

          categories.forEach((category, cIndex) => {
            // console.log('index', cIndex);
            chartData[cIndex] = 0;
            items.forEach((it) => {
              if (it.name === categories[cIndex]) {
                chartData[cIndex] = it[parameterNew] / 60;
              }
            });
          });

          // console.log('chartData', chartData);

          const workSeries = {
            name,
            type: 'bar',
            stack: index,
            connectNulls: true,
            data: chartData,
            step: 'middle',
            itemStyle: {
              color: colors[index % colors.length]['500'],
            },
            // label: {
            //   normal: {
            //     textBorderWidth: 3,
            //     textBorderColor: '#ffffff',
            //     fontSize: 16,
            //     verticalAlign: 'middle',
            //     show: true,
            //     rotate: 90,
            //     position: ['50%', '100%'],
            //     formatter: '{b}',
            //   },
            // },
          };

          echart.series.push(workSeries);
          echart.legend.data.push(name);
        }
      });

      this.echart.xAxis.data = [];
      this.echart.xAxis.data = categoriesFormated;
      this.data = newData;

      // console.log('this.echart', this.echart);

      this.render = true;
      this.loading = false;
    },
    remove() {
      this.$emit('remove', this.monitor);
    },
    onOptions(edited) {
      this.settings.edited = edited;
      this.echart.toolbox.show = edited;
      this.echart.legend.top = edited ? 40 : 0;
      this.echart.grid.top = edited ? 80 : 40;
    },
    async refresh() {
      if (this.settingsValid) {
        await this.fetchData();
      }
    },
    async resize(width = null) {
      const { chart } = this.$refs;
      // Delay process of resizing chart
      // by animation time of resizing widget
      await this.$sleep(300);

      if (chart && this.settingsValid) {
        chart.resize({
          width: width || 'auto',
        });
      }
    },
    saveSettings() {
      const { monitor, settings } = this;
      // const {
      //   devices, deviceGroups, byGroups,
      //   interval, parameter, expanded, edited,
      // } = settings;

      const {
        devices, deviceGroups, byGroups,
        parameter, expanded, edited,
      } = settings;

      monitor.params = {
        devices,
        deviceGroups,
        byGroups,
        parameter,
        // interval: interval ? interval.id : null,
        edited,
        expanded,
      };

      this.$emit('save');
    },
    async toXLSX() {
      const {
        dateRange,
        byGroups,
        devices,
        deviceGroups,
      } = this.settings;

      const {
        date,
      } = this;

      const from = format(dateRange[0], date.format);
      const to = format(dateRange[1], date.format);

      const params = {
        from,
        to,
        type: 'device-work-shift-chart',
      };

      if (byGroups) {
        params.groups = deviceGroups.join(',');
      } else {
        params.devices = devices.join(',');
      }

      const url = '/reports/factory/xlsx';
      this.fetched = false;

      await this.onExport({
        url,
        filetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        filename: `work-activity-chart-${from}-${to}`,
        params,
      });

      this.fetched = true;
    },
  },
};
</script>

<style scoped lang="stylus">
.progress
  z-index: 10
  position: absolute
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
</style>
