<template>
  <bkf-widget
    class="monitor"
    :class="monitorClass"
    :style="monitorStyle"
  >
    <template slot="title">
      <template v-if="showTitle">
        {{ 'diagnostics.title-device-operator-monitor'|trans }}
      </template>
    </template>
    <template slot="actions">
      <template v-if="showActions">
        <template v-if="settings.edited">
          <bkf-action-close
            @click="remove"
          />
          <bkf-action-expand
            v-if="!$vuetify.breakpoint.mobile"
            v-model="settings.expanded"
            @click="resize"
          />
          <bkf-action-refresh
            @click="refresh"
          />
        </template>
        <bkf-action-options
          @click="onOptions(!settings.edited)"
        />
      </template>
    </template>
    <transition name="fade">
      <div
        v-if="loading"
        class="progress"
      >
        <v-progress-circular
          :size="80"
          color="primary"
          indeterminate
        />
      </div>
    </transition>
    <template
      v-if="settings.edited"
    >
      <div class="mh-5">
        <template v-if="showDeviceOperators">
          <v-radio-group
            v-model="settings.byGroups"
            :mandatory="true"
            :hide-details="true"
            row
            dense
            class="mb-4 mt-2"
          >
            <v-radio
              :label="$trans('device-operator-monitor.device-operators')"
              :value="false"
            />
            <v-radio
              :label="$trans('device-operator-monitor.device-operators-groups')"
              :value="true"
            />
          </v-radio-group>
          <field-device-operators
            v-show="!settings.byGroups"
            v-model="settings.deviceOperators"
            :device-operators="deviceOperators"
          />
          <field-device-operators-groups
            v-show="settings.byGroups"
            v-model="settings.deviceOperatorsGroups"
            :device-operators-group="deviceOperatorsGroups"
          />
        </template>
      </div>
      <div class="flex flex-wrap">
        <div class="flex-1 mh-5 wmin-250">
          <field-parameter
            v-model="settings.parameter"
            :label="'monitor.parameter'|trans"
            :parameters="selectableParameters"
          />
        </div>
        <div class="flex-1 mh-5 wmin-250">
          <field-date-range
            v-model="settings.dateRange"
          />
        </div>
        <div class="flex-1 mh-5 wmin-250">
          <field-interval
            v-model="settings.interval"
            :show-month="showMonth"
          />
        </div>
      </div>
    </template>
    <div
      class="chart-container"
    >
      <v-alert
        v-if="message"
        type="warning"
        class="mt-0"
      >
        {{ message }}
      </v-alert>
      <chart
        v-if="settingsValid && render"
        ref="chart"
        :options="echart"
        :auto-resize="true"
      />
    </div>
    <bkf-refresher
      :speed="300"
      :pause="loading"
      @refresh="refresh"
    />
  </bkf-widget>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts.vue';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/axisPointer';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/toolbox';

// Date functions
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfToday from 'date-fns/start_of_today';
import isFirstDayOfMonth from 'date-fns/is_first_day_of_month';

import csv from '@utils/csv';
import { truthyAll } from '@utils/asserts';
import human from '@utils/human';
import { mapActions, mapGetters } from 'vuex';

import FieldDateRange from './fields/DateRange';
import FieldDeviceOperators from './fields/DeviceOperators';
import FieldDeviceOperatorsGroups from './fields/DeviceOperatorsGroup';
import FieldInterval from './fields/Interval';
import FieldParameter from './fields/Parameter';

import colors from './internals/colors';
import intervals from './internals/intervals';

export default {
  components: {
    chart: ECharts,
    FieldDateRange,
    FieldDeviceOperators,
    FieldDeviceOperatorsGroups,
    FieldInterval,
    FieldParameter,
  },
  props: {
    monitor: {
      required: true,
      type: Object,
    },
    deviceOperators: {
      required: false,
      type: Array,
      default: () => [],
    },
    deviceOperatorsGroups: {
      required: false,
      type: Array,
      default: () => [],
    },
    showTitle: {
      required: false,
      type: Boolean,
      default: true,
    },
    showActions: {
      required: false,
      type: Boolean,
      default: true,
    },
    showDeviceOperators: {
      required: false,
      type: Boolean,
      default: true,
    },
    expanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    edited: {
      required: false,
      type: Boolean,
      default: true,
    },
    width: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      render: true,
      loading: false,
      settings: {
        deviceOperators: [],
        deviceOperatorsGroups: [],
        interval: intervals[0],
        dateRange: [
          startOfToday(),
          startOfToday(),
        ],
        expanded: false,
        edited: true,
        byGroups: false,
        type: 'work',
        parameter: { number: 1, name: t`device-operator-monitor.title` },
      },
      parameters: [
        { number: 1, name: t`device-operator-monitor.title` },
        { number: 2, name: t`device-operator-monitor.activity` },
      ],
      date: {
        format: 'YYYY-MM-DD',
      },
      echart: {
        grid: {
          top: 80,
          right: 90,
          left: 30,
          bottom: 20,
          containLabel: true,
        },
        toolbox: {
          show: true,
          itemGap: 10,
          itemSize: 20,
          feature: {
            restore: {
              title: t`chart.restore`,
            },
            dataZoom: {
              title: {
                zoom: t`chart.area-zooming`,
                back: t`chart.restore-area-zooming`,
              },
            },
            saveAsImage: {
              title: t`chart.save-as-image`,
            },
            mySaveAsCSV: {
              show: true,
              title: t`chart.save-as-csv`,
              icon: 'path://M0,0h10l4,4v16h-14v-20M10,0v4h4M4,8l6,8m0,-8l-6,8',
              onclick: this.toCSV,
            },
            magicType: {
              type: ['line', 'bar'],
              title: {
                line: t`chart.line-chart`,
                bar: t`chart.bar-chart`,
              },
              option: {
                bar: {
                  animationEasing: 'elasticOut',
                  animationDelay: (idx) => idx * 10,
                },
                line: {
                  animationEasing: 'linear',
                },
              },
            },
          },
        },
        legend: {
          data: [],
          top: 40,
          type: 'scroll',
        },
        tooltip: {
          confine: true,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
            show: true,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: null,
          },
          axisLine: {
            show: false,
          },
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
        }],
        series: [],
        animationDuration: 300,
        color: colors,
      },
    };
  },
  computed: {
    ...mapGetters({
      menuVisible: 'menu/visible',
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
    monitorClass() {
      const { settings } = this;

      if (this.$vuetify.breakpoint.mobile) {
        return ['flex-basis-100p', 'flex-grow-1', 'flex-shrink-1'];
      }

      return {
        'width-50p': !settings.expanded,
        'flex-basis-100p flex-shrink-1 flex-grow-1': settings.expanded,
      };
    },
    monitorStyle() {
      const { settings } = this;
      if (!this.$vuetify.breakpoint.mobile && !settings.expanded) {
        return {
          width: 'calc(50% - 10px)',
        };
      }

      return {};
    },
    selectedDeviceOperators() {
      const { deviceOperators, byGroups } = this.settings;
      if (byGroups) {
        return [];
      }
      return this.deviceOperators.filter((d) => deviceOperators.includes(d.id));
    },
    selectedDeviceOperatorsGroups() {
      const { deviceOperatorsGroups, byGroups } = this.settings;
      if (!byGroups) {
        return [];
      }
      return this.deviceOperatorsGroups.filter((d) => deviceOperatorsGroups.includes(d.id));
    },
    // Settings validation state
    settingsValid() {
      const { selectedDeviceOperators, selectedDeviceOperatorsGroups } = this;
      const { interval, dateRange, byGroups } = this.settings;

      if (byGroups) {
        if (selectedDeviceOperatorsGroups.length === 0) {
          return false;
        }

        return truthyAll(
          dateRange[0],
          dateRange[1],
          interval,
          selectedDeviceOperatorsGroups,
        );
      }

      if (selectedDeviceOperators.length === 0) {
        return false;
      }

      return truthyAll(
        dateRange[0],
        dateRange[1],
        interval,
        selectedDeviceOperators,
      );
    },
    message() {
      const { settings, error } = this;

      if (!settings.deviceOperators.length && !settings.byGroups) {
        return t`device-operator-monitor.message.no-device-operators`;
      }

      if (!settings.deviceOperatorsGroups.length && settings.byGroups) {
        return t`device-operator-monitor.message.no-device-operators-groups`;
      }

      if (settings.deviceOperatorsGroups.length && error.error !== undefined) {
        return t`device-operator-group.no-device-operators`;
      }

      return '';
    },
    showMonth() {
      if (isFirstDayOfMonth(this.settings.dateRange[0])) {
        return true;
      }

      return false;
    },
    selectableParameters() {
      return this.parameters;
    },
  },
  watch: {
    settings: {
      deep: true,
      async handler(to, from) {
        // Save settings and resize chart
        this.saveSettings();
        this.resize();

        // check if setttings are valid
        if (!this.settingsValid) {
          return;
        }

        // Edit or expand mode was changed so not fetch Data
        if (to.edited !== from.edited || to.expanded !== from.expanded) {
          return;
        }

        this.fetchData();
      },
    },
    // Watch width value (if passed) and if its changed, then resize chart
    width(value) {
      this.resize(value);
    },
    // Resize if navigation menu is toggled
    menuVisible() {
      this.resize();
    },
  },
  mounted() {
    const {
      edited, expanded, interval, deviceOperators, deviceOperatorsGroups, parameter, byGroups,
    } = this.monitor.params || {
      interval: 'hour',
      edited: this.edited,
      expanded: this.expanded,
      parameter: this.settings.parameter,
      deviceOperators: [],
      deviceOperatorsGroups: [],
      byGroups: false,
    };

    if (deviceOperators.length) {
      this.settings.deviceOperators = this.deviceOperators
        .filter((deviceOperator) => deviceOperators.includes(deviceOperator.id))
        .map((deviceOperator) => deviceOperator.id);
    }

    if (byGroups) {
      this.settings.byGroups = byGroups;
    }

    if (deviceOperatorsGroups.length) {
      this.settings.deviceOperatorsGroups = this.deviceOperatorsGroups
        .filter((deviceOperatorsGroup) => deviceOperatorsGroups.includes(deviceOperatorsGroup.id))
        .map((deviceOperatorsGroup) => deviceOperatorsGroup.id);
    }

    if (interval) {
      this.settings.interval = intervals.find((i) => i.id === interval);
    }

    this.settings.edited = edited;
    this.settings.expanded = expanded;
    this.settings.parameter = parameter;
    this.onOptions(edited);
  },
  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggle',
      showMenu: 'menu/show',
      hideMenu: 'menu/hide',
    }),
    async fetchData() {
      const {
        echart,
        date,
        settings,
        selectedDeviceOperators,
        selectedDeviceOperatorsGroups,
      } = this;
      const { interval, dateRange } = settings;
      const deviceOperators = selectedDeviceOperators.map((deviceOperator) => deviceOperator.id).join(',');
      const deviceOperatorsGroups = selectedDeviceOperatorsGroups.map(
        (deviceOperatorsGroup) => deviceOperatorsGroup.id,
      ).join(',');

      const from = format(dateRange[0], date.format);
      const to = format(dateRange[1], date.format);
      const url = '/deviceoperators/diagnostics/multiple';
      this.loading = true;

      const response = await this.$http.get(url, {
        deviceOperators,
        deviceOperatorsGroups,
        interval: interval.id,
        from,
        to,
      });

      this.loading = false;

      const { data: { data }, status } = response;

      if (status === 400) {
        this.error = response.data;
        return;
      }

      this.error = {};

      // Detect if interval was changed by backend
      if (data.interval !== interval.id) {
        this.settings.interval = intervals.find((i) => i.id === data.interval);
      }

      const unit = {
        short: null,
        long: null,
      };

      // Reset settings
      delete echart.yAxis.axisLabel.formatter;
      delete echart.yAxis.splitNumber;
      delete echart.yAxis.min;
      delete echart.yAxis.max;
      delete echart.yAxis.interval;
      delete echart.yAxis.minInterval;
      delete echart.yAxis.maxInterval;
      delete echart.tooltip.precision;
      delete echart.tooltip.formatter;

      // Default tooltip formatter
      echart.tooltip.formatter = () => {
        let buffer;
        let title;

        return `${title}<br>${buffer}`;
      };

      // Default yAxis label formatter
      echart.xAxis.axisLabel.formatter = (value) => {
        const formatted = format(parse(value), settings.interval.trans)
          .replace(' ', '\n')
          .toLowerCase();
        const translated = formatted.replace(/datetime\.[a-z.]+/g, (match) => t`${match}`);
        return translated;
      };

      let highestValue = 0;
      let divider = 1;

      data.data.forEach((payload) => {
        if (this.settings.parameter.number === 1) {
          payload.work.forEach((value) => {
            highestValue = Math.max(highestValue, value);
          });
        }

        if (this.settings.parameter.number === 2) {
          payload.active.forEach((value) => {
            highestValue = Math.max(highestValue, value);
          });
        }
      });

      if (highestValue === 0) {
        divider = 1;
        unit.short = 's';
        unit.long = 'Seconds';
      }

      if (highestValue > 0) {
        divider = 1;
        unit.short = 's';
        unit.long = 'Seconds';
      }

      if (highestValue > 60) {
        divider = 60;
        unit.short = 'min';
        unit.long = 'Minutes';
      }

      if (highestValue > 3600) {
        divider = 3600;
        unit.short = 'h';
        unit.long = 'Hours';
      }

      const segments = highestValue / divider;
      const scale = Math.ceil(segments > 10 ? segments / 10 : 1) || 1;
      const axisInterval = divider * scale;

      echart.yAxis.axisLabel.formatter = (value) => `${value / divider} ${unit.short}`;
      echart.yAxis.interval = axisInterval;
      echart.yAxis.minInterval = axisInterval;
      echart.yAxis.maxInterval = axisInterval;
      echart.tooltip.formatter = (params) => {
        const {
          name,
          seriesName,
          seriesIndex,
          value,
        } = params;

        const title = this.settings.parameter.number === 2 ? t`device-operator-monitor.activity` : t`device-operator-monitor.tooltip.title`;
        const averageTitle = this.settings.parameter.number === 2 ? t`device-operator-monitor.avarage-activity` : t`device-operator-monitor.activity`;

        const work = {
          value: null,
          color: null,
          marker: null,
          index: seriesIndex,
        };

        const workSeries = echart.series[work.index];
        work.value = workSeries.data[params.dataIndex];
        work.color = workSeries.itemStyle.color;
        work.marker = `<span style="background-color: ${work.color};
          display: inline-block; width: 10px; height: 10px; border-radius: 10px"></span>`;

        const tooltipTitle = `${title}:`;
        const workRow = `${work.marker} ${seriesName}: <b>${human(work.value, { maxHours: true })}</b>`;

        if (name === '//average//') {
          return `${averageTitle}:<br>${work.marker} ${seriesName} <b>${human(value)}</b>`;
        }

        return `<b>${name}</b><br>${tooltipTitle}<br>${workRow}`;
      };

      this.render = false;
      await this.$sleep(100);

      echart.series = [];
      echart.legend.data = [];

      data.data.forEach((payload, index) => {
        const { id, name } = payload.deviceOperator;
        const chartData = this.settings.parameter.number === 1 ? payload.work : payload.active;

        const workSeries = {
          name,
          type: 'bar',
          stack: id,
          connectNulls: true,
          data: chartData,
          itemStyle: {
            color: colors[index % colors.length]['500'],
          },
        };

        echart.series.push(workSeries);
        echart.legend.data.push(name);
      });

      // Create categories
      this.echart.xAxis.data = data.categories;
      this.data = data.data;
      this.render = true;
    },
    remove() {
      this.$emit('remove', this.monitor);
    },
    onOptions(edited) {
      this.settings.edited = edited;
      this.echart.toolbox.show = edited;
      this.echart.legend.top = edited ? 40 : 0;
      this.echart.grid.top = edited ? 80 : 40;
    },
    async refresh() {
      if (this.settingsValid) {
        await this.fetchData();
      }
    },
    async resize(width = null) {
      const { chart } = this.$refs;
      // Delay process of resizing chart
      // by animation time of resizing widget
      await this.$sleep(300);

      if (chart && this.settingsValid) {
        chart.resize({
          width: width || 'auto',
        });
      }
    },
    toCSV() {
      const labels = ['DateTime'];
      const { series, xAxis } = this.echart;
      const dateTimes = xAxis.data;

      series.forEach((serie) => {
        labels.push(`${serie.name} [s]`);
      });

      const push = (value, list) => {
        list.push(value);
      };

      const rows = dateTimes.map((dateTime, index) => {
        const data = [dateTime];

        series.forEach((serie) => {
          push(Number(serie.data[index]).toFixed(3), data);
        });

        return data;
      });

      csv(rows, labels);
    },
    saveSettings() {
      const { monitor, settings } = this;
      const {
        deviceOperators,
        deviceOperatorsGroups,
        interval,
        expanded,
        edited,
        parameter,
        byGroups,
      } = settings;

      monitor.params = {
        deviceOperators,
        deviceOperatorsGroups,
        interval: interval ? interval.id : null,
        parameter,
        edited,
        expanded,
        byGroups,
      };

      this.$emit('save');
    },
  },
};
</script>

<style scoped lang="stylus" src="./DeviceOperatorMonitor.styl"></style>

<style scoped lang="stylus">
.progress
  z-index: 10
  position: absolute
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
</style>
