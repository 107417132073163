<template>
  <div>
    <transition
      name="page-switch"
      mode="out-in"
    >
      <bkf-page
        v-if="isActivePage"
        :loading="loading"
        :error="error"
        class="pt-3"
      >
        <bkf-widget class="pb-4">
          <v-row>
            <v-col>
              <field-device-groups
                v-model="settings.deviceGroups"
                :device-groups="deviceGroups"
              />
            </v-col>
            <v-col>
              <field-date-range
                v-model="settings.date.range"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                :disabled="!settingsValid || fetching"
                tile
                color="primary"
                class="ml-4 mt-2"
                @click="generate"
              >
                {{ 'report.action.generate'|trans }}
              </v-btn>
              <v-btn
                :disabled="fetching || report.length === 0"
                tile
                color="primary"
                class="mt-2"
                @click="toCSV"
              >
                {{ 'report.action.export'|trans }}
              </v-btn>
            </v-col>
          </v-row>
          <div
            v-if="message"
            class="message"
          >
            <v-alert
              type="warning"
              class="mt-0"
            >
              {{ message|trans }}
            </v-alert>
          </div>
          <v-data-table
            v-if="report.length"
            :headers="columns"
            :items="report"
            hide-default-footer
          >
            <template #[`item.alert`]="{ item }">
              <alerts-table
                :devices="item.devices"
              />
            </template>
          </v-data-table>
        </bkf-widget>
      </bkf-page>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { startOfToday, startOfMonth, format } from 'date-fns';
import { truthyAll } from '@utils/asserts';
import date from '@utils/date';
import csv from '@utils/csv';
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import FieldDeviceGroups from './fields/DeviceGroups';
import AlertsTable from './AlertsTable.vue';
import FieldDateRange from './fields/DateRange';

export default {
  components: {
    AlertsTable,
    FieldDeviceGroups,
    FieldDateRange,
  },
  mixins: [
    pageMixin,
    listMixin,
  ],
  data() {
    return {
      loading: false,
      fetching: false,
      fetched: false,
      report: [],
      deviceGroups: [],
      settings: {
        page: 1,
        size: 10,
        deviceGroups: [],
        date: {
          format: 'YYYY-MM-DD',
          range: [
            startOfMonth(startOfToday()),
            startOfToday(),
          ],
        },
      },
      columns: [
        {
          value: 'name',
          text: t`alerts-report.device-group`,
          sortable: true,
        }, {
          value: 'alert',
          text: t`alerts-report.alert.name`,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
    settingsValid() {
      const { date: dateRange, deviceGroups } = this.settings;

      return truthyAll(
        dateRange.range[0],
        dateRange.range[1],
        deviceGroups.length,
      );
    },
    message() {
      const { deviceGroups } = this.settings;

      if (deviceGroups.length === 0) {
        return t`report.message.no-device-groups-selected`;
      }

      if (this.fetched && this.report.length === 0) {
        return t`report.message.no-data-found`;
      }

      return null;
    },
  },
  methods: {
    async onActivatePage() {
      const DeviceGroup = await this.$model('DeviceGroup');
      const { entries } = await DeviceGroup.query({
        sort: { name: 'asc' },
      });

      this.deviceGroups = entries;
    },
    async generate() {
      this.fetchData();
    },
    async fetchData() {
      const { date: dateRange, deviceGroups } = this.settings;
      const url = '/reports/alarms_report';

      this.fetching = true;
      this.fetched = false;

      const { data, status } = await this.$http.get(url, {
        context: 'alerts_report',
        from: format(dateRange.range[0], dateRange.format),
        to: format(dateRange.range[1], dateRange.format),
        deviceGroups: deviceGroups.map((dg) => dg.id).join(','),
      });

      if (status === 200) {
        this.report = data;
      }

      if (status === 204) {
        this.report = [];
      }

      this.fetching = false;
      this.fetched = true;
    },
    toCSV() {
      const headers = [
        t`alerts-report.device-group`,
        t`alerts-report.alert.device`,
        t`alerts-report.alert.creation-time`,
        t`alerts-report.alert.definition`,
        t`alerts-report.alert.occurrences`,
      ];
      const deviceGroups = this.report;

      const rows = [];
      deviceGroups.forEach((deviceGroup) => {
        deviceGroup.devices.forEach((device) => {
          Object.keys(device.allAlarms).map((objectKey) => {
            const alarm = device.allAlarms[objectKey];
            return rows.push([
              deviceGroup.name,
              device.name,
              date(alarm.ctime, 'YYYY.MM.dd'),
              alarm.alertRule.name,
              alarm.counter,
            ]);
          });
        });

        return rows;
      });

      const { date: dateRange } = this.settings;
      const title = t`report.predefined.alerts.title`;

      csv(
        rows,
        headers,
        `${title} ${format(new Date(), 'YYYY-MM-DD H:mm')}\n\n`,
        { filename: `alerts-report-${format(dateRange.range[0], dateRange.format)}-${format(dateRange.range[1], dateRange.format)}` },
      );
    },
  },
};
</script>
