<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    flat
    :color="headerColor"
    :dark="headerStyle"
  >
    <v-app-bar-nav-icon
      @click.stop="toggleMenu"
    />

    <v-toolbar-title
      class="ml-0 pl-4"
    >
      <router-link
        v-if="brand === 'rejs'"
        :to="{ name: 'dashboard' }"
      >
        <img
          :class="{'logoOnMobile': isMobile}"
          src="@assets/images/rejs/logo.png"
          class="logo"
        >
        <img
          v-if="!isMobile"
          src="@assets/images/rejs/most.png"
          class="logo most"
        >
      </router-link>
      <router-link
        v-else-if="brand === 'precimet'"
        :to="{ name: 'dashboard' }"
      >
        <img
          src="@assets/images/precimet/logo.png"
          class="logo precimet"
        >
      </router-link>
      <router-link
        v-else
        :to="{ name: 'dashboard' }"
      >
        <img
          src="@assets/images/default/logo.png"
          class="logo mt-0"
          :class="{'logoOnMobile': isMobile}"
        >
      </router-link>
    </v-toolbar-title>
    <v-spacer />

    <v-tooltip
      bottom
      internal-activator
    >
      <template #activator="{ on, attrs }">
        <bkf-profile-menu
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          @guide="$emit('guide')"
        />
      </template>
      <span>{{ user.name }}</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    brand() {
      return this.config.brand.name;
    },
    ...mapGetters({
      config: 'config',
      user: 'users/current',
      isMobile: 'size/isMobile',
      menuVisible: 'menu/visible',
    }),
    headerColor() {
      if (this.brand === 'rejs') {
        return 'grey lighten-2';
      }

      return 'primary darken-4';
    },
    headerStyle() {
      if (this.brand === 'rejs') {
        return false;
      }

      return true;
    },
  },
  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggle',
      showMenu: 'menu/show',
      hideMenu: 'menu/hide',
    }),
    toggleMenu() {
      this.$emit('toggle-menu');
    },
    toggleAlerts() {
      this.$emit('toggle-alerts');
    },
  },
};
</script>

<style scoped lang="stylus" src="./Header.styl"></style>
