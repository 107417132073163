<template>
  <v-form
    v-if="!fetching"
    @keyup.native.enter="submit"
  >
    <bkf-form-field
      v-for="(field, name) in schema"
      :key="name"
      :ref="name"
      v-model="data[name]"
      :name="name"
      :field="field"
      :fetching="fetching || submitting"
      :choices="choices ? choices[name] : null"
      :errors="errors ? errors[name] : null"
      :options="options"
    />
    <v-btn
      tile
      color="primary"
      :loading="fetching || submitting"
      @click="submit"
    >
      {{ submitText|trans }}
    </v-btn>
  </v-form>
</template>

<script>
import BkfFormField from './FormField';

export default {
  name: 'BkfFormSimple',
  components: {
    BkfFormField,
  },
  props: {
    requestMethod: {
      type: String,
      required: false,
      default: 'put',
    },
    submitText: {
      type: String,
      required: false,
      default: 'dialog.update',
    },
    formUrl: {
      type: String,
      required: false,
      default: null,
    },
    actionUrl: {
      type: String,
      required: false,
      default: null,
    },
    context: {
      type: String,
      required: false,
      default: null,
    },
    entry: {
      required: false,
      type: [Object, Function],
      default: null,
    },
    model: {
      required: false,
      type: Function,
      default: null,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fetching: false,
      submitting: false,
      saving: false,
      data: null,
      schema: null,
      choices: null,
      errors: null,
      token: null,
      unit: null,
    };
  },
  computed: {
    normalized() {
      const normalized = {};
      Object.keys(this.schema).forEach((key) => {
        normalized[key] = this.data[key];
      });

      return normalized;
    },
  },
  watch: {
    data: {
      deep: true,
      async handler() {
        if (this.options.type === 'charging' && this.data.parameter === 10003) {
          if (this.data.parameter && this.schema.parameter.label === 'alert-rule.parameter') {
            this.schema.argument.label = t`alert-rule.condition.charging-quantity`;
          }
        } else if (this.options.type === 'percentage') {
          if (this.data.parameter && this.schema.parameter.label === 'alert-rule.parameter') {
            this.schema.argument.label = t`alert-rule.condition.percentage-difference`;
          }
        } else {
          if (this.data.parameter && this.schema.parameter.label === 'alert-rule.parameter') {
            this.unit = await this.getParameter(this.data.parameter);
            this.schema.argument.label = t`alert-rule.units.${this.unit}`;
          }
          if (this.data.parameter && this.schema.parameter.label === 'alert-rule.part-parameter') {
            this.schema.argument.label = t`alert-rule.units.Hours`;
          }
        }
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      // const { context, entry, options } = this;
      const { context, entry } = this;
      // const model = options.model || this.model || entry.$model;

      const params = entry ? {
        id: entry.id,
        action: 'edit',
      } : {
        action: 'new',
      };

      if (context) {
        params.contexts = [context];
      }

      // Set fetching state
      // this.fetching = true;
      this.$emit('fetching');
      const response = await this.$http.get(this.formUrl);
      const { data, status } = response;

      if (status === 200) {
        this.data = data.data;
        this.schema = data.schema;
        this.choices = data.choices;
        this.token = data.token || null;
        this.$emit('fetched', { data, response });
        // if (this.options.alertRuleType && this.options.alertRuleType === 'part replace') {
        //   this.schema.argument.label = t`alert-rule.units.Hours`;
        // } else {
        this.getLabel();
        // }
      }

      if (status >= 400) {
        this.$emit('fetch-error', { data, response });
      }

      this.fetching = false;
    },
    async submit() {
      const {
        entry, normalized, token,
      } = this;
      const payload = normalized;

      if (token) {
        payload.token = token;
      }

      // Set submitting state
      this.submitting = true;
      this.$emit('submitting');

      let response = null;
      if (this.requestMethod === 'put') {
        response = await this.$http.put(this.actionUrl, payload);
      } else if (this.requestMethod === 'post') {
        response = await this.$http.post(this.actionUrl, payload);
      } else {
        throw new Error('Request Method not found ');
      }

      const { data, status } = response;

      let eventName = 'submitted';

      // Check if form data was invalid
      if (status === 400 && data.errors) {
        this.errors = data.errors;

        if (this.errors.rootErrors) {
          this.errors.rootErrors.forEach((error) => {
            this.$notify.error(error);
          });
        }

        this.submitting = false;
        eventName = 'validate-error';
      }

      // Check if some error occured and this is not related to form validation
      if (status >= 400 && !data.errors) {
        eventName = 'submit-error';
      }

      const event = {
        response,
        formData: this.data,
      };

      if (entry) {
        event.entry = entry;
      }

      this.$emit(eventName, event);
    },
    async getParameter(id) {
      const { data } = await this.$http.get(`parameters/${id}/unit`);
      return data.name;
    },
    async getLabel() {
      if (this.options.parameterId && this.schema.argument) {
        this.unit = await this.getParameter(this.options.parameterId);
        this.schema.argument.label = t`alert-rule.units.${this.unit}`;
      }
    },
  },
};
</script>
