import CRUD from './CRUD';
import Create from './Create';
import Delete from './Delete';
import Update from './Update';
import UpdateSimple from './UpdateSimple';

export default {
  install(Vue) {
    Vue.use(CRUD);
    Vue.use(Create);
    Vue.use(Delete);
    Vue.use(Update);
    Vue.use(UpdateSimple);
  },
};
