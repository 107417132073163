<template>
  <bkf-widget>
    <template slot="title">
      {{ 'device-group.statistics'|trans }}
    </template>
    <v-list dense>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device-group.today-activity'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ stats.activeTime|human }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device-group.today-work'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ stats.workTime|human }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">{{ 'device-group.today-productivity'|trans }}:</span>
            </template>
            <span>{{ 'device-group.average-productivity-explanation'|trans }}</span>
          </v-tooltip>
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ stats.productivity ? stats.productivity + '%' : '-' }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </bkf-widget>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedInterval: 'today',
    };
  },
  computed: {
    ...mapGetters({
      devicesGroupDiagnostics: 'devices/devicesGroupDiagnostics',
    }),
  },
  methods: {
  },
};
</script>

<style scoped lang="stylus">
.progress
  z-index: 10
  width: 100%
  height: 100px
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
</style>
