<template>
  <div class="flex">
    <div
      class="flex-4 ellipsis"
    >
      <bkf-tooltip
        :class="{'chartOnMobile': isMobile}"
        :content="data.name"
      >
        <span
          class="clickable pl-50"
          @click="$router.push({ name: 'device', params: { id: data.id } })"
        >
          {{ data.name }}
        </span>
      </bkf-tooltip>
    </div>
    <div
      v-if="!isMobile"
      class="flex-10 mh-10"
    >
      <svg
        :viewBox="`0 0 ${width} 32`"
        :width="width"
        height="32"
        preserveAspectRatio="none"
      >
        <g
          stroke="none"
          fill="red"
        >
          <template v-for="(i, index) in 25">
            <rect
              v-if="i % 2"
              :key="index"
              :x="(i - 1) * (width / 24)"
              :width="width / 24"
              fill="#eee"
              y="0"
              height="32"
            />
          </template>
        </g>
        <g
          stroke="#999"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line
            :x2="width - 4"
            x1="2"
            y1="8"
            y2="8"
          />
        </g>
        <g
          class="enabled"
          stroke="none"
        >
          <template v-for="(activeInterval, index) in active">
            <rect
              v-if="activeInterval.w"
              :key="index"
              :x="activeInterval.x"
              :width="activeInterval.w"
              y="0"
              height="16"
            />
          </template>
        </g>
        <g
          class="working"
          stroke="none"
        >
          <template v-for="(workInterval, index) in work">
            <rect
              v-if="workInterval.w"
              :key="index"
              :x="workInterval.x"
              :width="workInterval.w"
              y="0"
              height="16"
            />
          </template>
        </g>
      </svg>
    </div>
    <div
      v-if="!isMobile"
      id="workTime"
      class="flex-3 center-align clickable"
    >
      {{ data.workSum|human }}
    </div>
    <div
      v-if="isMobile"
      class="flex-4 left-align avarageOnMobile pl-20"
      :class="{'blue': Math.round((data.workSum/data.activeSum)*100) > average}"
    >
      <div v-if="data.workSum">
        {{ Math.round((data.workSum/data.activeSum)*100) }}%
      </div>
      <div
        v-else
        class="noData"
      >
        {{ 'device.indicator.no-data'|trans }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import settingsMixin from '@mixins/misc/settings';

export default {
  mixins: [
    settingsMixin,
  ],
  props: {
    date: {
      required: true,
      type: Date,
    },
    data: {
      required: true,
      type: Object,
    },
    width: {
      required: true,
      type: Number,
    },
    average: {
      required: true,
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
    factor() {
      return this.width / 288;
    },
    active() {
      const { data, factor } = this;

      return data.active
        .map((value, index) => {
          if (value === 0) {
            return null;
          }

          const x = Math.ceil(factor * index);
          const w = Math.ceil((value / 300) * factor);
          return { x, w };
        })
        .filter((value) => value !== null);
    },
    work() {
      const { data, factor } = this;

      return data.work
        .map((value, index) => {
          if (value === 0) {
            return null;
          }
          const x = Math.ceil(factor * index);
          const w = Math.ceil((value / 300) * factor);
          return { x, w };
        })
        .filter((value) => value !== null);
    },
  },
};
</script>

<style scoped lang="stylus">
#workTime
  cursor default
working-color = #61a5f4
enabled-color = darken(#ddd, 15%)
.clickable
  font-size: 0.85em
.working
  fill: working-color
.enabled
  fill: enabled-color
.chartOnMobile
  padding-left 50%
  font-size: 0.85em;
.avarageOnMobile
  font-size: 0.85em
  color red
  font-weight bold
  padding-left 10%
  max-width 45%
.blue
  color #2857a5
  font-size: 0.85em
  font-weight bold
.noData
  color enabled-color
.onMobile
  max-width 180px
</style>
