<template>
  <form
    id="login_form"
    class="login__form reset__form"
    novalidate="novalidate"
    @submit.stop.prevent="reset"
  >
    <md-toolbar
      :md-theme="title.theme"
    >
      <span class="md-title">
        {{ title.message|trans }}
      </span>
    </md-toolbar>
    <div
      class="login__form__inner"
    >
      <md-input-container>
        <label>
          {{ 'reset.password.placeholder'|trans }}
        </label>
        <md-input
          id="new_password"
          v-model="password.first"
          name="new_password"
          type="password"
        />
      </md-input-container>
      <md-input-container>
        <label>
          {{ 'reset.password-repeat.placeholder'|trans }}
        </label>
        <md-input
          id="new_password_repeat"
          v-model="password.second"
          name="new_password_repeat"
          type="password"
        />
      </md-input-container>
      <div
        class="login__form__actions"
      >
        <router-link
          :to="{ name: 'login' }"
        >
          <md-button
            class="md-primary"
          >
            {{ 'reset.back'|trans }}
          </md-button>
        </router-link>
        <md-button
          class="md-primary md-raised"
          :disabled="resetDisabled"
          type="submit"
        >
          {{ 'reset.reset'|trans }}
        </md-button>
      </div>
    </div>
  </form>
</template>

<script>
import sleep from 'es7-sleep';

export default {
  props: {
    language: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      password: {
        first: '',
        second: '',
      },
      title: {
        theme: 'default',
        message: 'reset.title.default',
      },
      errors: {},
      token: null,
      resetDisabled: false,
    };
  },
  watch: {
    language() {
      this.$forceUpdate();
    },
  },
  created() {
    this.token = this.$route.query.token;
  },
  methods: {
    async reset() {
      const { password, title } = this;
      title.theme = 'alert';

      if (password.first === '') {
        title.message = 'reset.title.password-empty';
        return false;
      }

      if (password.first !== password.second) {
        title.message = 'reset.title.passwords-different';
        return false;
      }

      const { data, status } = await this.$api.patch('/password', {
        password: password.first,
        token: this.token,
      });

      if (status === 404) {
        title.message = 'reset.title.token-expired';
        return false;
      }

      if (status === 400) {
        title.message = data.error.plainPassword.msg;
        return false;
      }

      if (status === 200) {
        this.resetDisabled = true;
        title.message = 'reset.title.password-changed';
        title.theme = 'success';

        await sleep(3000);
        this.$router.replace({ name: 'login' });
      }

      return true;
    },
  },
};
</script>
