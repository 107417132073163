<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      >
        <v-btn
          color="primary"
          tile
          @click.native="openAddDialog()"
        >
          {{ 'device-operator.create'|trans }}
        </v-btn>
      </portal>
      <bkf-page
        v-if="isActivePage"
        :loading="loadingPage"
        :error="error"
        class="pt-3"
      >
        <bkf-widget v-if="workers.length">
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0"
            >
              <v-btn
                icon
                class="mt-4"
                @click="$refs.settings.open()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="workers"
            :item-class="rowClass"
            :items-per-page="settings.size"
            :search="search"
            @click:row="handleClick"
          >
            <template #[`item.device.name`]="{ item }">
              <device-button
                v-if="item.device"
                :device="item.device"
              />
            </template>
            <template #[`item.device`]="{ item }">
              <bkf-action-launch
                tooltip="device.go-to-device"
                @click="onDeviceClick(item.workerStatus.lastDevice.id)"
              >
                {{ item.workerStatus.lastDevice ? item.workerStatus.lastDevice.name : '-' }}
              </bkf-action-launch>
            </template>
            <template #[`item.workerStatus`]="{ item }">
              <span class="my-2 d-flex">
                <work-time-last-active-indicator
                  :status="$deep(item, 'workerStatus.lastActiveStatus')"
                  :last-work="$deep(item, 'workerStatus.lastWork')"
                  :work-time="$deep(item, 'stats.workTime')"
                />
                <operator-last-active-indicator
                  :name="`${$deep(item, 'firstname')} ${$deep(item, 'lastname')}`"
                  :status="$deep(item, 'workerStatus.lastActiveStatus')"
                  :last-sync="$deep(item, 'workerStatus.lastActive')"
                />
              </span>
            </template>
            <template #[`item.trend`]="{ item }">
              <work-trend-indicator
                :value="item.trend"
              />
            </template>
            <template #[`item.actions`]="{ item }">
              <bkf-action-edit @click="openUpdateDialog(item)" />
              <bkf-dialog-form-simple
                :key="`workerUpdateForm${item.id}`"
                :ref="`workerUpdateForm${item.id}`"
                :form-url="`worker/${item.id}/edit`"
                :action-url="`worker/${item.id}`"
                request-method="put"
                submit-text="dialog.save"
                title="device-operator.editing"
                @updated="onActivatePage"
              />
            </template>
          </v-data-table>
        </bkf-widget>
        <bkf-empty-state
          v-else
        />
      </bkf-page>
      <router-view />
      <bkf-dialog-form-simple
        key="addWorker"
        :ref="`workerAddForm`"
        :form-url="`workers/new`"
        :action-url="`worker`"
        request-method="post"
        submit-text="dialog.add"
        title="device-operator.create"
        @updated="onActivatePage"
      />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('device-operator.create')"
      />
      <bkf-page-settings
        ref="pageSettings"
        :settings.sync="settings"
        @loaded="onLoadedSettings"
      />
      <settings
        ref="settings"
        v-model="settings"
        @submit="onSettings"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import WorkTimeLastActiveIndicator from '@components/domains/device/indicators/WorkTimeLastActive';
import OperatorLastActiveIndicator from '@components/domains/device/indicators/OperatorLastActive';
import WorkTrendIndicator from '@components/domains/device/indicators/WorkTrend';
import DeviceButton from '@components/domains/device/buttons/Device';
import Settings from './Settings.vue';

export default {
  components: {
    Settings,
    WorkTimeLastActiveIndicator,
    OperatorLastActiveIndicator,
    WorkTrendIndicator,
    DeviceButton,
  },
  mixins: [pageMixin, listMixin, settingsMixin],
  data() {
    return {
      loadingPage: true,
      workers: [],
      search: '',
      settings: {
        size: 10,
        workInterval: 'month',
      },
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
    columns() {
      return [
        {
          text: t`device-operator.full-name`,
          value: 'name',
        },
        {
          text: t`device-operator.rfid`,
          value: 'rfid',
        },
        {
          text: t`device-operator.login-on-device`,
          value: 'device',
        },
        {
          value: 'workerStatus',
          text: t`device.status`,
          sortable: false,
        },
        {
          value: 'trend',
          text: t`device.trend`,
          align: 'center',
          sortable: false,
        },
        {
          text: t`device-operator.company-name`,
          value: 'companyName',
        },
        {
          name: 'actions',
          value: 'actions',
          align: 'right',
          sortable: false,
          filterable: false,
          exportable: false,
        },
      ];
    },
  },
  methods: {
    openUpdateDialog(item) {
      this.$refs[`workerUpdateForm${item.id}`].open();
    },
    openAddDialog() {
      this.$refs.workerAddForm.open();
    },
    async onActivatePage() {
      this.getData();
    },
    async getData() {
      this.loadingPage = true;
      const params = {
        context: 'details',
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `workers?${urlParams}`,
      )
        .then(
          (response) => {
            this.workers = response.data;
            this.loadingPage = false;
          },
        );
    },
    onDeviceClick(id) {
      this.$router.push({ name: 'device', params: { id } });
    },
    async onSettings({ workInterval }) {
      this.settings.workInterval = workInterval;
    },
    onLoadedSettings() {
      const { settings } = this;

      if (settings.workInterval === undefined) {
        this.$set(this.settings, 'workInterval', 'month');
      }
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'device-operator', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>
