<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      />
      <bkf-page
        v-if="isActivePage"
        :loading="false"
        :error="error"
        class="pt-3"
      >
        <bkf-widget>
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0 pt-7"
            >
              <bkf-action-refresh
                @click="refresh"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            v-if="!siteLoading"
            :loading="siteLoading"
            :headers="columns"
            :items="administrationUsers"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="id"
          >
            <template #[`item.lastLogin`]="{ item }">
              {{ item.lastLogin|dateTime }}
            </template>
          </v-data-table>
          <v-progress-circular
            v-if="siteLoading"
            color="primary"
            class="mb-8"
            size="60"
            indeterminate
          />
        </bkf-widget>
      </bkf-page>
      <router-view />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('device.create')"
      />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import debounce from 'lodash/debounce';
import indicatorsMixin from '@mixins/domain/device/indicators';

export default {
  mixins: [
    pageMixin,
    listMixin,
    indicatorsMixin,
  ],
  data() {
    return {
      expanded: [],
      siteLoading: true,
      search: null,
      settings: {
        size: 10,
      },
      footerProps: {
        'items-per-page-options': [5, 10, 25],
      },
      totalItems: 13,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      administrationUsers: [],
      columns: [
        {
          value: 'firstname',
          text: t`users.firstname`,
          sortable: false,
        },
        {
          value: 'lastname',
          text: t`users.lastname`,
          sortable: false,
        },
        {
          value: 'email',
          text: t`users.email`,
          sortable: false,
        },
        {
          value: 'lastLogin',
          text: t`users.last_login`,
          sortable: false,
        },
      ],
    };
  },
  watch: {
    search: {
      handler() {
        this.pagination.page = 1;
        this.getDataDebounced();
      },
      deep: true,
    },
    pagination: {
      handler(newValue, oldValue) {
        if (oldValue.page !== newValue.page
            || oldValue.itemsPerPage !== newValue.itemsPerPage) {
          // return to first page when sorting has change
          if (oldValue.itemsPerPage !== newValue.itemsPerPage) {
            // eslint-disable-next-line no-param-reassign
            newValue.page = 1;
          }
          this.siteLoading = true;
          this.getDataDebounced();
        }
      },
      deep: true,
    },
  },
  created() {
    this.siteLoading = true;
    this.getDataDebounced = debounce(() => {
      this.getData();
    }, 1000);
  },
  mounted() {
    this.siteLoading = true;
    this.getData();
  },
  methods: {
    onDeviceClick(deviceId) {
      this.$router.push({ name: 'device', params: { id: deviceId } });
    },
    async refresh() {
      this.loading = true;
      await this.getData();
      this.loading = false;
    },
    async getData() {
      this.siteLoading = true;
      const params = {
        page: [this.pagination.page],
        perPage: [this.pagination.itemsPerPage],
        search: [this.search],
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `/users?${urlParams}`,
      )
        .then(
          (response) => {
            this.administrationUsers = response.data.data;
            this.totalItems = parseInt(response.data.total, 10);
            this.siteLoading = false;
          },
        );
    },
  },
};
</script>

<style scoped lang="stylus">
.expand-table
  box-shadow: inset 0 0px 0px 0px !important;
</style>
