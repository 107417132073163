<template>
  <bkf-widget class="widget-effective-work">
    <div
      slot="title"
      class="flex flex-align-items-center"
    >
      <span>{{ 'widget.effective-work.title'|trans }}</span>
    </div>
    <interval
      v-if="loaded"
      v-model="date"
      :hours="settings.interval"
      :class="{'pb-10 flex-justify-center' : isMobile}"
    />
    <div
      v-if="isMobile"
      class="center-align pt-10 pb-10 averageOnMobile"
    >
      {{ 'widget.effective-work.average-value'|trans }}: {{ avgWork }}%
    </div>
    <template
      v-if="loaded"
      slot="actions"
    >
      <v-menu
        left
        min-width="250"
      >
        <template #activator="{ on: menu, attrs }">
          <v-tooltip
            bottom
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <span> {{ 'action-button.more'|trans }}</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item @click="openSettings">
            <v-list-item-icon>
              <v-icon>
                settings
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ 'widget.settings'|trans }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="startGuide('widget-effective-work')">
            <v-list-item-icon>
              <v-icon>
                help
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ 'widget.guide'|trans }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template
      v-if="loaded"
    >
      <settings
        ref="settings"
        v-model="settings"
        :device-groups="deviceGroups"
        @submit="saveSettings"
      />
      <div
        v-if="fetching"
        class="center-align pv-50"
      >
        <v-progress-circular
          :size="80"
          color="primary"
          indeterminate
        />
      </div>
      <template v-else>
        <chart
          v-if="data.length"
          :date="date"
          :data="data"
          :interval="settings.interval"
          @calc-sum="calcAvg"
        />
        <bkf-empty-state
          v-else
          :show-widget="false"
          :show-button="false"
        />
      </template>
    </template>
    <div
      v-else
      class="pv-50 center-align"
    >
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      />
    </div>
    <bkf-refresher
      :speed="60"
      :pause="fetching"
      @refresh="refresh"
    />
  </bkf-widget>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import { format, startOfDay, isToday } from 'date-fns';
import guideMixin from '@mixins/misc/guide';
import settingsMixin from '@mixins/misc/settings';
import { mapGetters } from 'vuex';
import Chart from './Chart.vue';
import Interval from './Interval.vue';
import Settings from './Settings.vue';
import guide from './guide';

export default {
  components: {
    Chart,
    Interval,
    Settings,
  },
  mixins: [
    guideMixin,
    settingsMixin,
  ],
  props: {
    deviceGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
      sum: 0,
      avgWork: 0,
      date: null,
      devices: [],
      loaded: false,
      fetching: false,
      settings: {
        deviceGroups: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
  },
  watch: {
    date() {
      this.fetchData();
    },
  },
  async created() {
    // Try to load settings from backend
    const settings = await this.getSetting('effective-work');
    this.settings = settings || this.settings;

    if (!this.settings.deviceGroups) {
      this.settings.deviceGroups = [];
    }

    // Calculate date
    const currentDate = new Date();
    this.date = startOfDay(currentDate);
    this.registerGuide(guide);
  },
  mounted() {
    this.$nextTick(() => {
      this.detectComponetResize();
    });
  },
  updated() {
    this.detectComponetResize();
  },
  methods: {
    calcAvg(sum) {
      this.sum = sum;
      this.avgWork = (Math.round((this.sum / this.data.length)));
    },
    openSettings() {
      this.$refs.settings.open();
    },
    async saveSettings() {
      await this.setSetting('effective-work', this.settings);
      this.fetchData();
    },
    async fetchData() {
      this.fetching = true;

      const { date, settings } = this;
      const url = '/devices/diagnostics/detailed_work';
      const payload = {
        deviceGroups: settings.deviceGroups.join(','),
        date: format(date, 'YYYY-MM-DD'),
      };

      const { data } = await this.$http.get(url, payload);
      this.data = data;

      this.fetching = false;
      this.loaded = true;
    },
    async refresh() {
      if (isToday(this.date)) {
        await this.fetchData();
      }
    },
    detectComponetResize() {
      const resizeObserver = new ResizeObserver((items) => {
        this.$emit('resize-masonry-item', items[0].target);
      });
      if (this.$el instanceof Element) {
        resizeObserver.observe(document.querySelector('.widget-effective-work'));
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.averageOnMobile
  text-align center
  margin-top 10px
  font-weight bold
</style>
