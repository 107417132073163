<template>
  <bkf-widget>
    <template slot="title">
      {{ 'device.work-statistics.summary'|trans }}
    </template>
    <v-list
      v-if="!noParameters"
      dense
    >
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device.work-statistics.period'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="text-body-2 py-1">
          <v-select
            v-model="selectedInterval"
            class="ml-2 wmax-200 pt-3"
            :items="intervals"
            dense
            @change="getWorkSummary"
          >
            <template
              #item="data"
            >
              {{ `device.work-statistics.${data.item}`|trans }}
            </template>
            <template
              #selection="data"
            >
              <span class="text-body-2">{{ `device.work-statistics.${data.item}`|trans }}</span>
            </template>
          </v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ active.name }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ (active.value ? active.value : '-')|human }}
        </v-list-item-content>
        <v-list-item-action>
          <span
            v-if="selectedInterval !== 'all'"
            class="ml-10"
          >
            <bkf-tooltip
              v-if="active.value"
              :content="$trans(tooltip)"
            >
              <bkf-trend-icon
                :value="active.percent"
              />
            </bkf-tooltip>
          </span>
        </v-list-item-action>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ work.name }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ (work.value ? work.value : '-')|human }}
        </v-list-item-content>
        <v-list-item-action>
          <span
            v-if="selectedInterval !== 'all'"
          >
            <bkf-tooltip
              v-if="work.value"
              :content="$trans(tooltip)"
            >
              <bkf-trend-icon
                :value="work.percent"
              />
            </bkf-tooltip>
          </span>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-alert
      v-if="noParameters"
      type="info"
      class="mt-0"
    >
      {{ message|trans }}
    </v-alert>
  </bkf-widget>
</template>

<script>

import parameterTrans from '@mixins/misc/parameterTrans';

export default {
  mixins: [parameterTrans],
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: {
        name: t`parameter.work`,
        value: 0,
        pastValue: 0,
        percent: null,
      },
      work: {
        name: t`parameter.active`,
        value: 0,
        pastValue: 0,
        percent: null,
      },
      noParameters: false,
      loading: false,
      tooltip: 'device.work-statistics.tooltip',
      message: 'device.work-statistics.info',
      selectedInterval: 'today',
      intervals: [
        'today',
        'yesterday',
        'week',
        'last_week',
        'month',
        'last_month',
        'all',
      ],
    };
  },
  created() {
    this.getWorkSummary();
  },
  methods: {
    async getWorkSummary() {
      this.loading = true;
      const url = `/device/${this.device.id}/works/${this.selectedInterval}`;
      const { data, status } = await this.$http.get(url);

      if (status !== 200) {
        this.active.value = 0;
        this.active.pastValue = 0;
        this.work.value = 0;
        this.work.pastValue = 0;
      } else {
        this.active.value = data.active.seconds;
        this.active.pastValue = data.active_past.seconds;
        this.work.value = data.work.seconds;
        this.work.pastValue = data.work_past.seconds;
      }

      this.active.percent = this.calculatePercentage(
        this.active.value,
        this.active.pastValue,
        this.calculatePeriodPercent(this.selectedInterval),
      );
      this.work.percent = this.calculatePercentage(
        this.work.value,
        this.work.pastValue,
        this.calculatePeriodPercent(this.selectedInterval),
      );
      this.loading = false;
    },
    calculatePercentage: (actual, past, day) => (
      past === 0 ? 100 : Math.floor(
        ((actual - (past * day)) / (past * day)) * 100,
      )),
    calculatePeriodPercent(period) {
      if (period === 'today') {
        return new Date().getHours() / 24;
      }
      if (period === 'week') {
        return new Date().getDay() / 7;
      }
      if (period === 'month') {
        return new Date().getDate() / 30;
      }
      return 1;
    },
    hasParameterWithTag(tagName) {
      return this.device.deviceType.parameters.filter(
        (first) => first.tags.find((el) => el === tagName),
      ).length > 0;
    },
    getParameterWithTag(tagName) {
      if (this.hasParameterWithTag(tagName)) {
        return this.device.deviceType.parameters.filter(
          (first) => first.tags.find((el) => el === tagName),
        ).shift()?.name;
      }
      return '-';
    },
  },
};
</script>
