<template>
  <div>
    <portal
      v-if="isActivePage"
      to="page-actions"
    >
      <bkf-redirect
        :options="alertRules"
        placeholder="alert-rule.go-to-rule"
      />
    </portal>
    <bkf-page
      :loading="loading"
      :error="error"
      class="pt-6"
    >
      <div
        v-if="alertRule"
        class="mt-n2"
      >
        <v-alert
          v-if="archived"
          type="info"
          class="mt-0"
        >
          {{ 'alert-rule.archived.warning-message'|trans }}
        </v-alert>
        <div class="grid">
          <alert-rule-details
            v-if="loaded"
            :archived="archived"
            :alert-rule="alertRule"
            class="grid-item grid-left-half"
            @edit="edit"
            @remove="remove"
            @resize-masonry-item="resizeMasonryItem"
          />
          <conditions
            :alert-rule="entry"
            :conditions-bus="conditionsBus"
            class="grid-item grid-right-half"
            @resize-masonry-item="resizeMasonryItem"
            @detect-componet-resize="detectComponetResize"
          />
          <assigned
            :alert-rule="entry"
            :device-groups-bus="deviceGroupsBus"
            :devices-bus="devicesBus"
            :settings="settings"
            class="grid-item grid-left-half"
            @resize-masonry-item="resizeMasonryItem"
            @detect-componet-resize="detectComponetResize"
          />
          <assigned-users
            :alert-rule="entry"
            :users-bus="usersBus"
            :settings="settings"
            class="grid-item grid-right-half"
            @resize-masonry-item="resizeMasonryItem"
            @detect-componet-resize="detectComponetResize"
          />
        </div>
      </div>
    </bkf-page>
    <bkf-crud
      ref="crud"
      @updated="updated"
    >
      <delete
        slot="delete"
        :devices-bus="devicesBus"
        :device-groups-bus="deviceGroupsBus"
        :users-bus="usersBus"
        :conditions-bus="conditionsBus"
        @deleted="deleted"
        @deleteError="onError"
        @fetchError="onError"
      />
    </bkf-crud>
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import Vue from 'vue';
import detailsMixin from '@mixins/page/details';
import pageMixin from '@mixins/page';
import propsMixin from '@mixins/router/props';
import AssignedUsers from './Users/AssignedUsers.vue';
import Conditions from './Conditions.vue';
import Delete from './Delete.vue';
import Assigned from './Assigned.vue';
import AlertRuleDetails from './AlertRuleDetails.vue';

export default {
  components: {
    Conditions,
    Delete,
    AssignedUsers,
    Assigned,
    AlertRuleDetails,
  },
  mixins: [
    detailsMixin,
    pageMixin,
    propsMixin,
  ],
  data() {
    return {
      settings: {
        devices: {
          page: 1,
          size: 5,
        },
        users: {
          page: 1,
          size: 5,
        },
      },
      loaded: false,
      model: null,
      conditionModel: null,
      conditionId: 0,
      entry: null,
      linkedEntry: {},
      alertRule: null,
      alertRules: [],
      devicesBus: null,
      deviceGroupsBus: null,
      conditionsBus: null,
      usersBus: null,
    };
  },
  computed: {
    archived() {
      if (this.entry) {
        return this.entry.status === 'h';
      }

      return false;
    },
  },
  created() {
    this.devicesBus = new Vue();
    this.deviceGroupsBus = new Vue();
    this.conditionsBus = new Vue();
    this.usersBus = new Vue();
  },
  methods: {
    async onActivatePage() {
      this.model = await this.$model('AlertRule');
      const { entries } = await this.model.query();
      this.entry = await this.model.get({
        id: Number(this.$route.params.id),
      });
      this.alertRule = this.entry.$data;
      this.alertRules = entries.filter((entry) => entry.id !== this.alertRule.id);
      this.loaded = true;
    },
    /**
     * Deleted entry
     */
    async deleted() {
      this.$router.push({ name: 'alert-rules' });
      this.$notify.success(t`alert-rule.deleted`);
    },
    onError({ response }) {
      if (response.status === 404) {
        this.$notify.error('form.error.not-found');
      } else {
        this.$notify.error('form.error.internal-error');
      }
    },
    resizeMasonryItem(item) {
      const grid = this.$el.getElementsByClassName('grid')[0];
      const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'), 10);
      const rowHeight = parseFloat(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'), 10);
      if (item.querySelector('.content')) {
        const rowSpan = Math.ceil(
          (item.querySelector('.content').getBoundingClientRect().height + 60) / (rowHeight + rowGap) + 2,
        );
        item.style.gridRowEnd = `span ${rowSpan}`;
      }
    },
    resizeAllMasonryItems() {
      const allItems = this.$el.getElementsByClassName('grid-item');
      for (let i = 0; i < allItems.length; i += 1) {
        this.resizeMasonryItem(allItems[i]);
      }
    },
    detectComponetResize(element) {
      const resizeObserver = new ResizeObserver((item) => {
        this.resizeMasonryItem(item[0].target);
      });
      if (element instanceof Element && element !== null) {
        resizeObserver.observe(element);
      }
    },
  },
};
</script>

<style scoped lang="stylus">
  .warning-box
    background-color: #f7ba2a
    color: white
    padding: 10px
    i
      color: white

  .grid
    display grid
    grid-column-gap 10px
    grid-row-gap 10px
    grid-template-columns 1fr 1fr
    grid-auto-rows 10px
    grid-auto-flow column
  .grid-left-half
    grid-column-start 1
  .grid-right-half
    grid-column-start 2
  @media only screen and (max-width: 910px)
    .grid
      grid-template-columns 1fr
    .grid-right-half
      grid-column-start 1
</style>
