<template>
  <form
    id="login_form"
    class="login__form"
    novalidate="novalidate"
    @submit.stop.prevent="login"
  >
    <md-toolbar
      :md-theme="title.theme"
    >
      <span class="md-title">{{ title.message|trans }}
      </span>
    </md-toolbar>
    <div
      class="login__form__inner"
    >
      <md-input-container
        :class="{'md-input-invalid': usernameInvalid}"
      >
        <label>{{ 'login.username.placeholder'|trans }}</label>
        <md-input
          id="username"
          v-model="username"
          name="username"
        />
        <span
          v-if="usernameInvalid"
          class="md-error"
        >
          {{ 'login.username.invalid'|trans }}
        </span>
      </md-input-container>
      <md-input-container
        :class="{'md-input-invalid': passwordInvalid}"
      >
        <label>{{ $trans('login.password.placeholder') }}
        </label>
        <md-input
          id="password"
          v-model="password"
          type="password"
          name="password"
        />
      </md-input-container>
    </div>
    <div
      class="login__form__actions"
    >
      <router-link
        :to="{ name: 'reset' }"
      >
        <md-button
          class="md-primary"
        >
          {{ 'login.password.reset'|trans }}
        </md-button>
      </router-link>
      <md-button
        class="md-primary md-raised"
        :disabled="loginDisabled"
        type="submit"
      >
        {{ 'login.login'|trans }}
      </md-button>
    </div>
  </form>
</template>

<script>
import sleep from 'es7-sleep';
import OAuthManager from '@services/oauth';

// services
let oauthManager;

export default {
  props: {
    language: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      config: null,
      username: '',
      password: '',
      device: '',
      firstTry: true,
      progress: false,
      title: {
        theme: 'default',
        message: 'login.title.default',
      },
    };
  },
  computed: {
    usernameInvalid() {
      return !this.username.length && !this.firstTry;
    },
    passwordInvalid() {
      return !this.password.length && !this.firstTry;
    },
    loginDisabled() {
      return this.progress || this.usernameInvalid || this.passwordInvalid;
    },
  },
  watch: {
    language() {
      this.$forceUpdate();
    },
  },
  created() {
    oauthManager = OAuthManager.get({
      api: this.$api,
      url: '/token',
    });

    // Set oauth client
    const { id, secret } = CONFIG.oauth;
    oauthManager.setClient(id, secret);
  },
  methods: {
    async login() {
      const {
        username,
        password,
        device,
        title,
      } = this;
      this.firstTry = false;

      if (this.usernameInvalid || this.passwordInvalid) {
        return;
      }

      this.progress = true;
      title.theme = 'default';
      title.message = 'login.title.default';

      const { error, success } = await oauthManager.authorize(username, password);

      if (error) {
        title.theme = 'alert';

        if (error === 'invalid_grant') {
          title.message = 'login.title.invalid-credentials';
        } else if (error === 'invalid_client') {
          title.message = 'login.title.invalid-client';
        } else {
          title.message = 'login.title.internal-error';
        }
      }

      if (success) {
        if (device) {
          this.sendDevice(device);
        }
        title.theme = 'success';
        title.message = 'login.title.logged-in';

        await sleep(1000);
        window.location = CONFIG.url.application;
        this.$router.push('dashboard');
        this.$router.go();

        return;
      }

      this.progress = false;
      await sleep(5000);
      title.theme = 'default';
      title.message = 'login.title.default';
    },
    sendDevice(device) {
      const api = oauthManager.getApi();
      const token = oauthManager.getAccessToken();
      const url = this.$url(`/api/users/current/mobiles?access_token=${token}`);

      api.post(url, { device });
    },
  },
};
</script>
