<template>
  <div class="device-tab-work mb-4">
    <div class="flex flex-align-items-center flex-justify-end">
      <div class="year flex">
        <bkf-action-previous-page @click="subYear" />
        <div class="fs-11r mt-1">
          {{ year }}
        </div>
        <bkf-action-next-page @click="addYear" />
      </div>
      <div class="mr-2">
        {{ 'device-operator.when-operator'|trans }}:
      </div>
      <v-radio-group
        v-model="show"
        :mandatory="true"
        row
        dense
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-radio
              :label="$trans('device-operator.was-worked-effectively')"
              value="work"
              v-on="on"
            />
          </template>
          <span>{{ 'device-operator.worked-efficient-explanation'|trans }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-radio
              :label="$trans('device-operator.was-enabled')"
              value="active"
              v-on="on"
            />
          </template>
          <span>{{ 'device-operator.was-active-explanation'|trans }}</span>
        </v-tooltip>
      </v-radio-group>
    </div>
    <div class="chart">
      <e-charts
        ref="heatmap"
        class="echarts-heatmap"
        :options="heatmap"
        :autoresize="true"
      />
    </div>
  </div>
</template>

<script>
import { zip } from 'ramda';
import getYear from 'date-fns/get_year';
import human from '@utils/human';
import ECharts from 'vue-echarts/components/ECharts';
import 'echarts';

export default {
  components: {
    ECharts,
  },
  props: {
    deviceOperator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      show: 'work',
      rawData: null,
      year: getYear(new Date()),
      chartYear: getYear(new Date()),
    };
  },
  computed: {
    heatmap() {
      return {
        tooltip: {
          position: 'top',
          formatter({ data }) {
            const value = human(data[1]);
            return `${data[0]}: ${value}`;
          },
        },
        visualMap: {
          type: 'piecewise',
          show: true,
          min: 0,
          max: 86400,
          itemGap: 8,
          pieces: [
            { max: 0, label: '-' },
            { min: 1, max: 3600, label: '1h' },
            { min: 3600, max: 7200, label: '2h' },
            { min: 7200, max: 10800, label: '3h' },
            { min: 10800, max: 14400, label: '4h' },
            { min: 14400, max: 18000, label: '5h' },
            { min: 18000, max: 21600, label: '6h' },
            { min: 21600, max: 25200, label: '7h' },
            { min: 25200, label: '8h - 24h' },
          ],
          inRange: {
            color: [
              '#CBCBCB',
              '#7BB1DE',
              '#2695F3',
              '#2086E2',
              '#1E80DB',
              '#1973CC',
              '#1469C1',
              '#0A4DA1',
              '#003586',
            ],
          },
        },
        calendar: [{
          top: 30,
          left: 150,
          right: 30,
          range: this.chartYear,
          cellSize: ['auto', 20],
          splitLine: {
            show: false,
          },
          itemStyle: {
            normal: {
              color: '#e5e5e5',
              borderWidth: 2,
              borderType: 'solid',
              borderColor: '#fff',
            },
          },
          dayLabel: {
            firstDay: 1,
            nameMap: t`datetime.day-all-shorts-us`.split(','),
          },
          monthLabel: {
            formatter({ nameMap }) {
              return t`datetime.month-short.${nameMap.toLowerCase()}`;
            },
          },
          yearLabel: {
            margin: 50,
          },
        }],
        series: [{
          type: 'heatmap',
          coordinateSystem: 'calendar',
          calendarIndex: 0,
          data: this.chartData,
        }],
      };
    },
    chartData() {
      const { show, rawData } = this;

      if (rawData === null) {
        return [];
      }

      const { categories, work, active } = rawData;

      if (show === 'work') {
        return zip(categories, work);
      }

      return zip(categories, active);
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const { deviceOperator, year } = this;
      const url = `/worker/${deviceOperator.id}/work/annual`;
      this.loading = true;
      const { data } = await this.$http.get(url, { year });
      this.rawData = data.data;
      this.chartYear = this.year;
      this.loading = false;
    },
    resize({ width }) {
      this.$refs.heatmap.resize({ width });
    },
    addYear() {
      this.year += 1;
      this.fetchData();
    },
    subYear() {
      this.year -= 1;
      this.fetchData();
    },
  },
};
</script>

<style scoped lang="stylus">
.echarts
  height: 200px
  min-height: auto
</style>
