<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="65%"
    eager
    transform-origin="bottom center"
    @click:outside="closeDialog"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="closeDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0 flex-grow-1">
        <production-view-creator
          v-if="showDialog"
          :edit-prod-view="editProdView"
          :edited-production-view="editedProductionView"
          :prodviewtype="prodviewtype"
          @close-dialog="closeDialog"
          @send-parameters="sendParameters"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductionViewCreator from '../creator/ProductionViewCreator.vue';

export default {
  components: {
    ProductionViewCreator,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    editProdView: {
      type: Boolean,
      default: false,
    },
    prodviewtype: {
      type: Array,
      required: false,
      default: () => [],
    },
    editedProductionView: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      model: null,
      options: {},

    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
  },
  methods: {
    openDialog() {
      this.$emit('open-configurator-dialog');
    },
    closeDialog() {
      this.$emit('close-configurator-dialog');
    },
    sendParameters(params) {
      this.$emit('send-parameters', params);
    },
  },
};
</script>
