<template>
  <bkf-widget>
    <template slot="title">
      {{ 'device-operator-group.statistics'|trans }}
    </template>
    <v-list dense>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                {{ 'device-operator-group.today-activity'|trans }}:
              </span>
            </template>
            <span>{{ 'device-operator-group.average-activity-time'|trans }}</span>
          </v-tooltip>
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ stats.activeTime|human }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                {{ 'device-group.today-work'|trans }}:
              </span>
            </template>
            <span>{{ 'device-operator-group.average-work-time'|trans }}</span>
          </v-tooltip>
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ stats.workTime|human }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                {{ 'device-group.today-productivity'|trans }}:
              </span>
            </template>
            <span>{{ 'device-operator-group.average-productivity-time'|trans }}</span>
          </v-tooltip>
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ stats.productivity }}%
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </bkf-widget>
</template>

<script>

export default {
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedInterval: 'today',
    };
  },
};
</script>
