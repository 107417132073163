<template>
  <div>
    <portal
      v-if="isActivePage"
      to="page-actions"
    >
      <bkf-redirect
        v-if="!$vuetify.breakpoint.mobile"
        :options="filteredDevices"
        placeholder="device.go-to-device"
      />
    </portal>
    <bkf-page
      :loading="loading"
      :error="error"
      class="pt-3"
    >
      <div v-if="device">
        <div class="flex flex-wrap flex-justify-space-between flex-align-end">
          <bkf-widget style="flex-basis: 30%">
            <template slot="title">
              {{ 'device.details'|trans }}
            </template>
            <template slot="actions">
              <bkf-action-edit @click="openUpdateDialog(device)" />
              <bkf-dialog-form-simple
                :ref="`deviceUpdateForm${device.id}`"
                :form-url="`device/${device.id}/edit`"
                :action-url="`device/${device.id}`"
                request-method="put"
                submit-text="dialog.save"
                title="device.edit"
              />
              <bkf-action-delete
                v-if="user.extras.isSuperAdmin"
                :tooltip="$trans('action-button.assign')"
                @click="remove"
              />
            </template>
            <v-list dense>
              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.name'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ device.name }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.description'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ device.description || '-' }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="device.technical_status"
                class="hmin-30"
              >
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.technical-status'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ `device.statuses.${device.technical_status}`|trans }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.group'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  <template v-if="device.deviceGroups && device.deviceGroups.length">
                    <span
                      v-for="group in device.deviceGroups"
                      :key="group.id"
                      class="flex flex-wrap"
                    >
                      <bkf-action-launch
                        tooltip="device.go-to-device-group"
                        @click="redirectToDeviceGroup(group.id)"
                      >
                        {{ group.name }}
                      </bkf-action-launch>
                    </span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.type'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ device.deviceType.name }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.operator'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  <span v-if="device.deviceStatus.lastWorker">
                    <bkf-action-launch
                      tooltip="device.go-to-operator"
                      @click="redirectToOperator(device.deviceStatus.lastWorker.id)"
                    >
                      {{ device.deviceStatus.lastWorker.name }}
                    </bkf-action-launch>
                  </span>
                  <span v-else>
                    -
                  </span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.last-sync'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  <span v-if="device.sensors.length">
                    <template v-if="device.deviceStatus.lastOnline">
                      {{ device.deviceStatus.lastOnline|dateTime }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </span>
                  <span v-else>
                    -
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.sensors'|trans }}:
                </v-list-item-content>
                <v-list-item-content>
                  <bkf-action-launch
                    v-for="sensor in device.sensors"
                    :key="sensor.id"
                    tooltip="device.go-to-operator"
                    @click="redirectToSensor(sensor.id)"
                  >
                    {{ sensor.name }}
                  </bkf-action-launch>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </bkf-widget>

          <work-summary
            :device="device"
            class="ml-3"
            style="flex-basis: 30%"
          />

          <bkf-widget
            v-if="!isMobile"
            class="mt-3"
          >
            <v-tabs
              @change="tabOpened"
            >
              <v-tab>
                {{ 'device.work-time'|trans }}
              </v-tab>
              <v-tab-item>
                <tab-work
                  v-if="!isMobile && device"
                  :device="device"
                />
                <p v-else>
                  {{ 'device.hide-on-mobile'|trans }}
                </p>
              </v-tab-item>

              <v-tab>
                {{ 'device.alerts'|trans }}
              </v-tab>
              <v-tab-item>
                <tab-alerts v-model="device" />
              </v-tab-item>
              <v-tab>
                {{ 'device.diagnostics'|trans }}
              </v-tab>
              <v-tab-item>
                <bkf-monitor
                  v-if="!isMobile"
                  id="device_monitor"
                  :show-title="false"
                  :show-actions="false"
                  :show-devices="false"
                  :width="tabWidth - 100"
                  :devices="device2"
                  :device-groups="deviceGroups"
                  :monitor="monitor"
                  :device-types="deviceTypes"
                  class="mb-4"
                  :flat="true"
                />
              </v-tab-item>

              <v-tab>
                {{ 'device.gps-position'|trans }}
              </v-tab>
              <v-tab-item>
                <bkf-geo-monitor
                  v-if="!isMobile && showGpsPosition"
                  :show-title="false"
                  :show-actions="false"
                  :show-devices="false"
                  :width="tabWidth - 100"
                  :devices="device2"
                  :device-groups="deviceGroups"
                  :monitor="geoMonitor"
                  :flat="true"
                />
              </v-tab-item>
              <v-tab>
                {{ 'device.work-history'|trans }}
              </v-tab>
              <v-tab-item>
                <tab-history
                  :device="device"
                />
              </v-tab-item>
            </v-tabs>
          </bkf-widget>
        </div>
      </div>
    </bkf-page>
    <bkf-crud
      ref="crud"
      @updated="updated"
      @deleted="deleted"
    >
      <delete
        slot="delete"
        @deleted="deleted"
      />
    </bkf-crud>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import indicatorsMixin from '@mixins/domain/device/indicators';
import detailsMixin from '@mixins/page/details';
import pageMixin from '@mixins/page';
import propsMixin from '@mixins/router/props';
import settingsMixin from '@mixins/misc/settings';
import Delete from './Delete.vue';
import TabAlerts from './tabs/Alerts';
import TabWork from './tabs/Work';
import TabHistory from './tabs/History';
import WorkSummary from './WorkSummary.vue';

export default {
  components: {
    Delete,
    TabAlerts,
    TabWork,
    WorkSummary,
    TabHistory,
  },
  mixins: [
    indicatorsMixin,
    detailsMixin,
    pageMixin,
    propsMixin,
    settingsMixin,
  ],
  data() {
    return {
      model: null,
      entry: null,
      device: null,
      device2: [],
      deviceGroups: [],
      showGpsPosition: false,
      // filteredDevices: [],
      deviceTypes: [],
      powerPending: false,
      tabWidth: 100,
    };
  },
  computed: {
    monitor() {
      // Fake monitor object for Monitor component
      return {
        id: 0,
        type: 'monitor',
        params: {
          devices: [this.id],
          deviceGroups: [],
          parameter: null,
          parameters: [],
          edited: true,
          expanded: true,
        },
        save() {},
      };
    },
    geoMonitor() {
      return {
        id: 'device-geomonitor',
        type: 'geoMonitor',
        params: {
          devices: [this.id],
          deviceGroups: [],
          edited: true,
          expanded: true,
        },
        save() {},
      };
    },
    ...mapGetters({
      devices: 'devices/devices',
      user: 'users/current',
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
    filteredDevices() {
      return this.device === null ? []
        : this.devices.filter((device) => device.id !== this.id);
    },
  },

  methods: {
    async getData() {
      this.siteLoading = true;

      const deviceResponse = await this.$http.get(`/device/${this.id}`);
      this.device = deviceResponse.data;
      this.deviceTypes = [{
        id: this.device.deviceType.id,
        parameters: this.device.parameters,
      }];

      this.device2 = [this.device]; // jako parametr gdzie potrzebna jest tablica

      this.siteLoading = false;
    },
    async onActivatePage() {
      this.getData();
    },
    async tabOpened(tab) {
      if (tab === 3) {
        await this.$sleep(200);
        this.showGpsPosition = true;
      }
    },
    redirectToOperator(id) {
      this.$router.push({ name: 'device-operator', params: { id } });
    },
    redirectToSensor(id) {
      this.$router.push({ name: 'sensor', params: { id } });
    },
    redirectToDeviceGroup(id) {
      this.$router.push({ name: 'device-group', params: { id } });
    },
    async refreshDevice() {
      await this.getData();
      await this.entry.sync();
    },
    openUpdateDialog(item) {
      this.$refs[`deviceUpdateForm${item.id}`].open();
    },
  },
};
</script>

<style lang="stylus">
#map-device-geomonitor
  height: 300px
#device-monitor
  box-shadow: none
</style>
