import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[(_vm.showAvatar)?_c('img',{staticClass:"avatar-preview",attrs:{"src":_vm.showAvatar}}):_c(VIcon,{staticClass:"default_avatar"},[_vm._v("\n      mdi-account\n    ")])],1),_vm._v(" "),_c(VCol,[_c(VForm,{ref:"form"},[_c(VFileInput,{attrs:{"label":_vm.$trans('profile.settings.avatar'),"accept":"image/png, image/jpeg","prepend-icon":"mdi-camera","change":"getAvatar($event)"}}),_vm._v(" "),_c(VTextField,{attrs:{"label":_vm.$trans('profile.settings.first-name'),"required":"","error-messages":_vm.getInputValidation('firstname')},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_vm._v(" "),_c(VTextField,{attrs:{"label":_vm.$trans('profile.settings.last-name'),"required":"","error-messages":_vm.getInputValidation('lastname')},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }