<template>
  <div class="page-devices-location">
    <bkf-page
      v-if="isActivePage"
      :loading="loadingPage"
      :error="error"
      class="full-height"
    >
      <div class="flex mt-3 full-height">
        <bkf-widget class="panel">
          <v-row>
            <v-col
              offset="5"
              cols="1"
              class="mt-4"
            >
              <bkf-action-refresh
                @click="getData()"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                :label="$trans('action-button.search')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                v-if="!siteLoading"
                class="scrollable-table"
                :style="mapStyle"
                :loading="siteLoading"
                :headers="columns"
                :items="devicesAsync"
                :footer-props="footerProps"
                :options.sync="pagination"
                :server-items-length="totalItems"
                :single-expand="true"
                item-key="id"
              >
                <template #[`item.name`]="{ item }">
                  <div>
                    {{ item.name }}
                    <div
                      v-if="item.deviceStatus.deviceLocation !== null"
                      class="caption blue-grey--text lighten-1"
                    >
                      {{ $deep(item, 'deviceStatus.deviceLocation.address') }}
                    </div>
                  </div>
                </template>
                <template #[`item.deviceStatus`]="{ item }">
                  <span class="d-flex py-2">
                    <module-activity-indicator
                      :value="item.deviceStatus.online"
                      :last-sync="$deep(item, 'deviceStatus.lastOnline')"
                    />
                    <location-indicator
                      v-if="item.deviceStatus.locationStatus !== null"
                      :key="item.id"
                      :status="item.deviceStatus.locationStatus"
                      :last-sync="item.deviceStatus.deviceLocation !== null
                        ? item.deviceStatus.deviceLocation.time : null"
                      @toggle-visibility="toggleVisibilty(item)"
                    />
                  </span>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    v-if="item.deviceStatus.deviceLocation !== null"
                    icon
                    @click="toggleVisibilty(item)"
                  >
                    <v-icon :class="{ 'primary--text': isDeviceShown(item.id) }">
                      mdi-check
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-progress-circular
                v-if="siteLoading"
                color="primary"
                class="mb-8"
                size="60"
                indeterminate
              />
            </v-col>
          </v-row>
        </bkf-widget>
        <bkf-widget
          class="map ml-3"
        >
          <div
            id="devicesLocation"
            ref="mapElement"
            class="mapa mx-n4"
          />
        </bkf-widget>
      </div>
    </bkf-page>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import L from 'leaflet';
import pageMixin from '@mixins/page';
import settingsMixin from '@mixins/misc/settings';
import {
  startOfToday,
  endOfToday,
  format,
  parse,
  differenceInMinutes,
} from 'date-fns';
import indicatorsMixin from '@mixins/domain/device/indicators';
import colors from './internals/colors';

export default {
  mixins: [
    pageMixin,
    settingsMixin,
    indicatorsMixin,
  ],
  data() {
    return {
      siteLoading: false,
      loadingPage: false,
      edited: false,
      circles: [],
      pointers: [],
      noDataMessage: [],
      devicesWithRoute: [],
      devicesToShow: [],
      devicesFiltered: [],
      gpsRoute: [],
      dateRange: [
        startOfToday(),
        endOfToday(),
      ],
      color: '',
      fillColor: '',
      show: true,
      mapSource: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: {
        lat: 51.919438,
        lon: 19.145136,
      },
      zoom: 7,
      flyToZoom: 16,
      map: null,
      date: {
        format: 'YYYY-MM-DD',
        slider: 1,
      },
      data: [],
      search: '',
      devicesAsync: [],
      totalItems: 0,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      footerProps: {
        'items-per-page-options': [5, 10, 25],
      },
      mapStyle: 'max-height: 100px',
      columns: [
        {
          value: 'deviceStatus',
          text: t`device.status`,
          sortable: false,
        },
        {
          value: 'name',
          text: t`device.name`,
          sortable: false,
        },
        {
          value: 'actions',
          text: t``,
          align: 'right',
          sortable: false,
          filterable: false,
          exportable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
    }),
  },
  watch: {
    search: {
      handler() {
        this.pagination.page = 1;
        this.clearMap();
        this.getDataDebounced();
      },
      deep: true,
    },
    pagination: {
      handler(newValue, oldValue) {
        if (oldValue.page !== newValue.page
            || oldValue.itemsPerPage !== newValue.itemsPerPage) {
          // return to first page when sorting has change
          if (oldValue.itemsPerPage !== newValue.itemsPerPage) {
            // eslint-disable-next-line no-param-reassign
            newValue.page = 1;
          }
          this.siteLoading = true;
          this.clearMap();
          this.getDataDebounced();
        }
      },
      deep: true,
    },
  },
  created() {
    this.getDataDebounced = debounce(() => {
      this.getData();
    }, 1000);
  },
  async mounted() {
    // Prevents error "Error: Map container not found."
    // await this.$sleep(1000);
    await this.getData();
    this.$nextTick(() => {
      this.initMap();
    });
    this.devicesFiltered = this.devices;

    const mapHeight = document.getElementById('devicesLocation').offsetHeight - 130;
    this.mapStyle = `max-height: ${mapHeight}px`;
  },
  methods: {
    async getData() {
      this.siteLoading = true;
      const params = {
        page: [this.pagination.page],
        perPage: [this.pagination.itemsPerPage],
        search: [this.search],
        context: 'details',
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `devices?${urlParams}`,
      )
        .then(
          (response) => {
            this.devicesAsync = response.data;
            this.totalItems = parseInt(response.headers['x-pager-total-entries'], 10);
            this.siteLoading = false;
          },
        );
    },
    devicesRouteChange(devicesWithRoute) {
      this.devicesWithRoute = devicesWithRoute;
      this.addRoute();
    },
    toggleVisibilty(device) {
      const { id } = device;
      this.data[id] = device;

      if (this.devicesToShow.includes(id)) {
        this.devicesToShow = this.devicesToShow.filter((item) => item !== id);
      } else {
        this.devicesToShow.push(id);
      }

      this.devicesShowChange(this.devicesToShow);
    },
    isDeviceShown(id) {
      return this.devicesToShow.includes(id);
    },
    devicesShowChange(devicesToShow) {
      this.devicesToShow = devicesToShow;
      this.redrawMap();
    },
    clearMap() {
      this.devicesToShow = [];
      this.redrawMap();
    },
    initMap() {
      const {
        center, zoom, mapSource,
      } = this;
      this.map = L.map(this.$refs.mapElement, {
        // turn off small textarea on botton-right corner
        attributionControl: false,
      })
        .setView([center.lat, center.lon], zoom);

      L.tileLayer(mapSource).addTo(this.map);
    },
    async resize() {
      // This is required to invoke after resize map container
      // to recalculate map dimensions
      await this.$sleep(200);
      this.map.invalidateSize();
    },
    redrawMap() {
      this.circles.forEach((circle) => this.map.removeLayer(circle));
      this.circles = [];

      this.pointers.forEach((pointer) => this.map.removeLayer(pointer));
      this.pointers = [];

      const latLngs = [];
      this.noData = false;
      this.listDevice = [];

      this.onSwitch = false;
      this.show = true;

      this.data.forEach((device, index) => {
        if (this.devicesToShow.includes(device.id)) {
          if (device?.deviceStatus?.deviceLocation === null) {
            this.noData = true;
            this.noDataMessage.push(device.name);
          }
          if (device.deviceStatus.deviceLocation) {
            const {
              time,
              lat,
              lon,
              acc,
            } = device.deviceStatus.deviceLocation;
            const formattedDate = format(parse(time), 'YYYY-MM-DD HH:mm');
            const color = colors[index % colors.length][900];
            const fillColor = colors[index % colors.length][500];
            const pointer = L.circle([lat, lon], {
              color,
              fillColor,
              fillOpacity: 1,
              radius: 7,
            }).bindTooltip(this.getDeviceTooltip(device, formattedDate), {
              permanent: true,
            });
            pointer.addTo(this.map);
            this.pointers.push(pointer);

            const circle = L.circle([lat, lon], {
              color: null,
              fillColor,
              fillOpacity: 0.3,
              radius: Math.min(1000, acc),
            });

            circle.addTo(this.map);
            this.circles.push(circle);

            latLngs.push([lat, lon]);
          } else {
            this.listDevice.push(device.name);
            this.noData = true;
          }
        }
      });
      if (latLngs.length) {
        this.map.fitBounds(latLngs, {
          maxZoom: 14,
        });
      }
      this.resize();
    },
    getDeviceTooltip(device, date) {
      const iconColor = this.deviceActive(device.id) ? '#00c853' : '#ddd';
      const icon = `<i class="v-icon el-tooltip sensor-indicator material-icons" style="color: ${iconColor};font-size: 14px;">fiber_manual_record</i>`;

      return `${icon}<strong>${device.name}</strong><br>${date}`;
    },
    async fetchData() {
      const { date, devices, dateRange } = this;
      const from = format(dateRange[0], date.format);
      const to = format(dateRange[1], date.format);
      const url = '/devices/diagnostics/location';
      this.loading = true;

      const { data, status } = await this.$http.get(
        url,
        {
          devices: devices.map((device) => device.id).join(','),
          from,
          to,
        },
      );

      this.loading = false;

      if (status === 200) {
        this.data = data.data.data;
      }
      this.redrawMap();
    },
    fitToBounds(bounds) {
      if (bounds.length || bounds.isValid()) {
        this.map.fitBounds(bounds);
      }
    },
    deviceActive(deviceId) {
      const device = this.devices.find((dev) => dev.id === deviceId);
      const now = new Date();
      now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000);

      return differenceInMinutes(now, this.$deep(device.diagnostics, 'sensor_last_active.date')) < 9;
    },
  },
};
</script>

<style lang="stylus" scoped>
  .page-devices-location
    height: calc(100vh - 172px)
  .active
    color: #00c853
  .full-height
    height 100%
  .widget.map
    flex-basis: calc(90% - 10px);
    z-index 0
    align-items: stretch;
    display: flex;
  #devicesLocation
    display flex
    min-width 100%
    min-height 100%
  .map >>> .content
    height 100%
    width 100%
    margin-left 0

  @media only screen and (max-width: 600px)
    .container
      flex-direction column;
    .container >>> .panel
      flex-basis : 0
      height 100vh
    .widget.map
      flex-basis: 0;

  .scrollable-table
    //max-height: 300px /* Set the desired height for the scrollable card */
    overflow-y: auto /* Enable vertical scrolling */
</style>
