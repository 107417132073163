<template>
  <bkf-widget>
    <template slot="title">
      {{ 'device-operator-group.assigned-device-operators'|trans }}
    </template>
    <template
      v-if="loaded"
      slot="actions"
    >
      <bkf-action-add
        :tooltip="$trans('action-button.assign')"
        @click="$refs.dialog.open"
      />
    </template>

    <template v-if="loaded">
      <v-data-table
        v-if="linkedDeviceOperators.length"
        :headers="columns"
        :items="linkedDeviceOperators"
        :item-class="rowClass"
        :items-per-page="settings.size"
        @click:row="handleClick"
      >
        <template
          v-for="h in columns"
          #[`header.${h.value}`]
        >
          <v-tooltip
            v-if="h.tooltip"
            :key="h.value"
            top
          >
            <template #activator="{ on }">
              <span v-on="on">{{ h.text }}</span>
            </template>
            <span>{{ h.tooltip }}</span>
          </v-tooltip>
          <template v-else>
            {{ h.text }}
          </template>
        </template>
        <template #[`item.name`]="{ item }">
          <sensor-indicator
            :last-sync="item.diagnostics|deep('operator_last_active.date')"
          />
          {{ item.name }}
        </template>
        <template #[`item.stats.active`]="{ item }">
          {{ item.stats.activeTime | human }}
        </template>
        <template #[`item.stats.work`]="{ item }">
          {{ item.stats.workTime | human }}
        </template>
        <template #[`item.stats.productivity`]="{ item }">
          {{ item.stats.productivity ? item.stats.productivity + '%' : '-' }}
        </template>
        <template #[`item.actions`]="{ item }">
          <bkf-action-delete @click="unlink([item.id])" />
        </template>
      </v-data-table>
      <v-alert
        v-else
        type="info"
        tile
        class="mt-0"
      >
        {{ 'device-operator-group.no-device-operators'| trans }}
      </v-alert>
    </template>
    <assign-dialog
      ref="dialog"
      :device-operators="unlinkedDeviceOperators"
      @submit="link"
    />

    <bkf-page-settings :settings.sync="componentSettings" />
  </bkf-widget>
</template>

<script>
import { without } from 'ramda';
import AssignDialog from './AssignDeviceOperatorDialog.vue';
import SensorIndicator from './SensorIndicator.vue';

export default {
  components: {
    AssignDialog,
    SensorIndicator,
  },
  props: {
    deviceOperatorGroup: {
      type: Object,
      required: true,
    },
    deviceOperators: {
      type: Array,
      required: true,
    },
    deviceOperatorBus: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      linkedDeviceOperatorsIds: [],
      componentSettings: this.settings,
      config: {
        multiSelect: false,
      },
      columns: [
        {
          value: 'name',
          text: t`device-operator-group.name`,
        },
        {
          value: 'stats.active',
          text: t`device-operator-group.today-single-activity`,
          tooltip: t`device-operator-group.average-activity-time`,
        },
        {
          value: 'stats.work',
          text: t`device-group.work`,
          tooltip: t`device-operator-group.average-work-time`,
        },
        {
          value: 'stats.productivity',
          text: t`device-group.productivity`,
          tooltip: t`device-operator-group.average-productivity-time`,
        },
        {
          value: 'actions',
          text: '',
          sortable: false,
        }],
    };
  },
  computed: {
    linkedDeviceOperators() {
      return this.deviceOperators.filter(
        (deviceOerator) => this.linkedDeviceOperatorsIds.includes(deviceOerator.id),
      );
    },
    unlinkedDeviceOperators() {
      return this.deviceOperators.filter(
        (deviceOerator) => !this.linkedDeviceOperatorsIds.includes(deviceOerator.id),
      );
    },
  },
  created() {
    this.deviceOperatorBus.$on('unassign-device-operator', this.onUnassign);
  },
  async mounted() {
    this.linkedDeviceOperatorsIds = this.deviceOperatorGroup.workers.map((g) => g.id);

    this.loaded = true;
  },
  methods: {
    rowClass() {
      return 'clickable';
    },
    handleClick({ id }) {
      this.$router.push({ name: 'device-operator', params: { id } });
    },
    async link(ids) {
      const { deviceOperatorGroup } = this;
      this.loaded = false;
      await this.$http.patch(`/deviceoperatorgroups/${deviceOperatorGroup.id}/deviceoperators/${ids.join(',')}/assign`);
      await deviceOperatorGroup.sync();
      this.linkedDeviceOperatorsIds.push(...ids);
      this.$notify.success(t`device-operator-group.assigned-device-operators`);
      this.loaded = true;
    },
    async unlink(ids) {
      const { deviceOperatorGroup } = this;
      this.loaded = false;
      await this.$http.patch(`/deviceoperatorgroups/${deviceOperatorGroup.id}/deviceoperators/${ids.join(',')}/unassign`);
      await deviceOperatorGroup.sync();
      this.linkedDeviceOperatorsIds = without(ids, this.linkedDeviceOperatorsIds);
      this.$notify.success(t`alert-rule.unassign-device`);
      this.loaded = true;
    },
    onUnassign(...id) {
      this.linkedDeviceOperatorsIds = without(id, this.linkedDeviceOperatorsIds);
    },
  },
};
</script>
