import { create } from 'apisauce';

// Symbols defining internal (private) fields
const symbols = {
  api: Symbol('$api'),
  url: Symbol('$url'),
  key: Symbol('$key'),
  instance: Symbol('$instance'),
  oauth: Symbol('$aouth'),
  client: Symbol('$client'),
};

/**
 * OAuthManager is responsible for communication with backend for token authentication
 */
export default class OAuthManager {
  [symbols.api] = null;

  [symbols.key] = null;

  [symbols.url] = null;

  [symbols.oauth] = null;

  [symbols.client] = null;

  static [symbols.instance] = null;

  constructor({ key = 'fm-oauth', url = '/token' } = {}) {
    this[symbols.url] = url;
    this[symbols.key] = key;
    this[symbols.oauth] = JSON.parse(localStorage.getItem(this[symbols.key])) || {};

    this[symbols.api] = create({
      baseURL: '/',
    });

    this[symbols.api].setBaseURL('');
  }

  /**
   * Authorize with username and password
   * @param {string} username
   * @param {string} password
   * @return {{}} Error and Success states
   */
  async authorize(username, password) {
    const { id, secret } = this[symbols.client];
    try {
      // Wysyłanie zapytania POST
      const { data, status } = await this[symbols.api].post(
        this[symbols.url],
        {
          client_id: id,
          client_secret: secret,
          grant_type: 'password',
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json', // Zmienna jeśli serwer oczekuje JSON
          },
        },
      );

      let success = true;
      let error = data.error || null;

      if (status >= 400) {
        error = 'internal_error';
      }

      if (error) {
        success = false;
      }

      if (success) {
        localStorage.setItem(this[symbols.key], JSON.stringify(data));
      }

      return {
        error,
        success,
      };
    } catch (error) {
      // Obsługa błędów
      return {
        error: error.response?.data?.error || 'unknown_error',
        success: false,
      };
    }
  }

  /**
   * Refresh access token by using refresh token acquired after authorization
   * @return {{}} Error and Success states
   */
  async refresh() {
    const { id, secret } = this[symbols.client];

    try {
      // Wysyłanie żądania POST do endpointu tokenów
      const { data, status } = await this[symbols.api].post(
        this[symbols.url],
        {
          client_id: id,
          client_secret: secret,
          grant_type: 'refresh_token',
          refresh_token: this[symbols.oauth]?.refresh_token, // Pobranie aktualnego refresh tokena
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      let success = true;
      let error = data.error || null;

      if (status >= 400) {
        error = 'internal_error';
      }

      if (error) {
        localStorage.removeItem(this[symbols.key]);
        localStorage.removeItem('fm-oauth');
        success = false;
      }

      if (success) {
        // Aktualizacja tokenów w LocalStorage i obiekcie OAuth
        localStorage.setItem(this[symbols.key], JSON.stringify(data));
        this[symbols.oauth] = data;
      }

      return {
        error,
        success,
      };
    } catch (error) {
      // Obsługa błędów podczas żądania
      return {
        error: error.response?.data?.error || 'unknown_error',
        success: false,
      };
    }
  }

  /**
   * Impersonate with new data
   * @param {*} data
   */
  impersonate(data) {
    localStorage.setItem(this[symbols.key], JSON.stringify(data));
    this[symbols.oauth] = data;
  }

  /**
   * Get OAuth object with internal data
   * @return {{}}
   */
  getOAuth() {
    return this[symbols.oauth];
  }

  /**
   * Get OAuth object with internal data
   * @return {{}}
   */
  get $oauth() {
    return this.getOAuth();
  }

  /**
   * Get access token
  * @return {string|null}
   */
  getAccessToken() {
    return this[symbols.oauth].access_token || null;
  }

  /**
   * Get access token
   * @return {string|null}
   */
  get $accessToken() {
    return this.getAccessToken();
  }

  /**
   * Get refresh token
   * @return {string|null}
   */
  getRefreshToken() {
    return this[symbols.oauth].refresh_token || null;
  }

  /**
   * Get refresh token
   * @return {string|null}
   */
  get $refreshToken() {
    return this.getRefreshToken();
  }

  /**
   * Get expires time
   * @return {number|null}
   */
  getExpires() {
    return this[symbols.oauth].expires_in || null;
  }

  /**
   * Get expires time
   * @return {number|null}
   */
  get $expires() {
    return this.getExpires();
  }

  /**
   * Set client
   * @param {string} id
   * @param {secret} secret
   */
  setClient(id, secret) {
    this[symbols.client] = { id, secret };
  }

  /**
   * Get client
  * @return { id, secret }
   */
  getClient() {
    return this[symbols.client];
  }

  /**
   * Get api
   * @return {Object}
   */
  getApi() {
    return this[symbols.api];
  }

  /**
   * Get api
   * @return {Object}
   */
  get $api() {
    return this.getApi();
  }

  /**
   * Get instance
   * @return {OAuthManager}
   */
  static get(payload) {
    OAuthManager[symbols.instance] = OAuthManager[symbols.instance] || new OAuthManager(payload);

    return OAuthManager[symbols.instance];
  }
}
