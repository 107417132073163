<template>
  <div class="device-operator">
    <portal
      v-if="isActivePage"
      to="page-actions"
    />
    <bkf-page
      :loading="loading"
      :error="error"
      class="pt-3"
    >
      <div
        v-if="worker"
        class="device-operator-page flex flex-wrap"
      >
        <bkf-widget>
          <template slot="title">
            {{ 'device-operator.details'|trans }}
          </template>
          <template slot="actions">
            <bkf-action-edit @click="openUpdateDialog(worker)" />
            <bkf-dialog-form-simple
              :ref="`workerUpdateForm${worker.id}`"
              :form-url="`worker/${worker.id}/edit`"
              :action-url="`worker/${worker.id}`"
              request-method="put"
              submit-text="dialog.save"
              title="device-operator.editing"
              @updated="onActivatePage()"
            />
            <bkf-action-delete
              :tooltip="$trans('action-button.assign')"
              @click="remove"
            />
          </template>
          <v-list dense>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.full-name'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ worker.name }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.rfid'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ worker.rfid || '-' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.device-operator-group'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                <bkf-action-launch
                  v-for="(group, index) in worker.workerGroups"
                  :key="index"
                  tooltip="device-operator.go-to-group"
                  @click="redirectToOperatorsGroup(group.id)"
                >
                  {{ group.name }}
                </bkf-action-launch>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.company-name'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ worker.companyName || '-' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'sensor.last-active'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ worker|deep('workerStatus.lastActive')|dateTime }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.login-on-device'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                <bkf-action-launch
                  tooltip="device.go-to-device"
                  @click="redirectToDevice(oworker.workerStatus.lastDevice.id)"
                >
                  {{ worker.workerStatus.lastDevice ? worker.workerStatus.lastDevice.name : '-' }}
                </bkf-action-launch>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </bkf-widget>
        <work-history
          :device-operator="worker"
          class="ml-3"
        />
        <bkf-widget
          v-if="!$vuetify.breakpoint.mobile"
          class="mt-3"
        >
          <v-tabs>
            <v-tab>
              {{ 'device-operator.worked-time'|trans }}
            </v-tab>
            <v-tab-item>
              <work
                :device-operator="worker"
              />
            </v-tab-item>
          </v-tabs>
        </bkf-widget>
      </div>
    </bkf-page>
    <bkf-crud
      ref="crud"
      @updated="updated"
    >
      <delete
        slot="delete"
        :devices-bus="devicesBus"
        @deleted="deleted"
        @deleteError="onError"
        @fetchError="onError"
      />
    </bkf-crud>
  </div>
</template>

<script>
import Vue from 'vue';
import detailsMixin from '@mixins/page/details';
import pageMixin from '@mixins/page';
import propsMixin from '@mixins/router/props';
import Work from './tabs/Work.vue';
import Delete from './Delete.vue';
import WorkHistory from './fields/WorkHistory.vue';

export default {
  components: {
    Delete,
    Work,
    WorkHistory,
  },
  mixins: [
    detailsMixin,
    pageMixin,
    propsMixin,
  ],
  data() {
    return {
      model: null,
      worker: null,
      entry: null,
      linkedEntry: {},
      devicesBus: new Vue(),
      tabWidth: 100,
    };
  },
  computed: {
    monitor() {
      // Fake monitor object for Monitor component
      return {
        id: 0,
        type: 'work',
        params: {
          deviceOperators: [this.worker.id],
          deviceOperatorsGroups: [this.worker.worker_groups],
          parameter: {
            number: 1,
          },
          edited: true,
          expanded: true,
        },
        save() {},
      };
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    openUpdateDialog(item) {
      this.$refs[`workerUpdateForm${item.id}`].open();
    },
    getData() {
      this.loading = true;
      const params = {
        context: 'details',
      };

      const urlParams = new URLSearchParams(params).toString();
      this.$http.get(
        `/worker/${this.$route.params.id}?${urlParams}`,
      )
        .then(
          (response) => {
            this.worker = response.data;

            this.loading = false;
          },
        );
      this.loading = false;
    },
    async onActivatePage() {
      this.getData();
    },
    onRowClick({ id }) {
      this.$router.push({ name: 'device', params: { id } });
    },
    async deleted() {
      this.$router.push({ name: 'device-operators' });
      this.$notify.success(t`device-operator.deleted`);
    },
    onError({ response }) {
      if (response.status === 404) {
        this.$notify.error('form.error.not-found');
      } else {
        this.$notify.error('form.error.internal-error');
      }
    },
    redirectToOperatorsGroup(id) {
      this.$router.push({ name: 'device-operator-group', params: { id } });
    },
    redirectToDevice(deviceId) {
      this.$router.push({ name: 'device', params: { id: deviceId } });
    },
  },
};
</script>

<style lang="stylus" scoped>
#device_operator_monitor
  box-shadow: none
.widget.mobileWidget
  flex-basis: 100%
</style>
