<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      />
      <bkf-page
        v-if="isActivePage"
        :loading="loadingPage"
        :error="error"
        class="pt-3"
      >
        <bkf-widget v-if="workerGroups.length">
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="workerGroups"
            :item-class="rowClass"
            :items-per-page="settings.size"
            :search="search"
            @click:row="handleClick"
          >
            <template
              v-for="h in columns"
              #[`header.${h.value}`]
            >
              <v-tooltip
                v-if="h.tooltip"
                :key="h.value"
                top
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ h.text }}</span>
                </template>
                <span>{{ h.tooltip }}</span>
              </v-tooltip>
              <template v-else>
                {{ h.text }}
              </template>
            </template>
            <template #[`item.stats.activeTime`]="{ item }">
              {{ item.stats.activeTime|human }}
            </template>
            <template #[`item.stats.workTime`]="{ item }">
              {{ item.stats.workTime|human }}
            </template>
            <template #[`item.stats.productivity`]="{ item }">
              {{ item.stats.productivity ? item.stats.productivity + '%' : '-' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <bkf-action-edit @click="edit(item)" />
            </template>
          </v-data-table>
        </bkf-widget>
        <bkf-empty-state v-else />
      </bkf-page>
      <router-view />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('device-operator-group.create')"
      />
      <bkf-page-settings :settings.sync="settings" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';

export default {
  mixins: [
    pageMixin,
    listMixin,
    settingsMixin,
  ],
  data() {
    return {
      search: '',
      loadingPage: true,
      settings: {
        size: 10,
      },
      workerGroups: [],
      columns: [{
        value: 'name',
        text: t`device-operator-group.name`,
      },
      {
        value: 'workers.length',
        text: t`device-operator-group.devices-operator-number`,
      },
      {
        value: 'stats.activeTime',
        text: t`device-group.activity`,
      },
      {
        value: 'stats.workTime',
        text: t`device-group.work`,
      },
      {
        value: 'stats.productivity',
        text: t`device-group.productivity`,
      },
      {
        value: 'actions',
        sortable: false,
        filterable: false,
        exportable: false,
      }],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
  },
  methods: {
    async onActivatePage() {
      await this.getData();
    },
    async getData() {
      this.loadingPage = true;
      const params = {
        context: 'details',
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `workergroups?${urlParams}`,
      )
        .then(
          (response) => {
            this.workerGroups = response.data;
            this.loadingPage = false;
          },
        );
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'device-operator-group', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>
