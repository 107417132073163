<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="65%"
    eager
    transform-origin="bottom center"
    @click:outside="closeDialog"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'support.title'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="closeDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-4 flex-grow-1">
        <v-alert
          type="info"
          class="mt-0"
        >
          {{ 'support.info'|trans }}
        </v-alert>
        <v-form
          ref="form"
        >
          <v-text-field
            v-model="subject"
            required
            :rules="rules"
            :label="$trans('support.subject')"
          />
          <v-textarea
            v-model="content"
            :rules="rules"
            required
            rows="3"
          >
            <template #label>
              <div>
                {{ 'support.content'|trans }}
              </div>
            </template>
          </v-textarea>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          tile
          @click="submit"
        >
          {{ 'support.submit'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: null,
      subject: '',
      content: '',
      rules: [
        (v) => !!v || t`support.no-empty-values`,
      ],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
  },
  methods: {
    openDialog() {
      this.$emit('open-support-dialog');
    },
    closeDialog() {
      this.$emit('close-support-dialog');
    },
    async submit() {
      const validation = this.$refs.form.validate();

      if (validation === false) {
        return;
      }

      this.$emit('submit-support-dialog', {
        content: this.content,
        subject: this.subject,
      });
      this.content = '';
      this.subject = '';
      this.closeDialog();
    },
  },
};
</script>
