<template>
  <bkf-widget class="assigned-users">
    <template slot="title">
      {{ 'alert-rule.users.assigned-users'|trans }}
    </template>
    <template
      v-if="loaded"
      slot="actions"
    >
      <bkf-action-add
        :tooltip="$trans('action-button.assign')"
        @click="$refs.dialog.open"
      />
    </template>
    <template v-if="loaded">
      <v-data-table
        v-if="linkedUsers.length"
        :headers="columns"
        :items="linkedUsers"
        :hide-default-footer="true"
      >
        <template #[`item.actions`]="{ item }">
          <template v-if="isUnlinkable(item)">
            <bkf-action-delete @click="unlink([item.id])" />
          </template>
        </template>
      </v-data-table>
      <v-alert
        v-else
        type="info"
        class="mt-0"
      >
        {{ 'alert-rule.users.no-users'| trans }}
      </v-alert>
    </template>
    <div
      v-else
      class="pv-50 center-align"
    >
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      />
    </div>
    <assign-dialog
      ref="dialog"
      :users="unlinkedUsers"
      @submit="link"
    />
    <bkf-page-settings :settings.sync="componentSettings" />
  </bkf-widget>
</template>

<script>
import { without } from 'ramda';
import { mapGetters } from 'vuex';
import AssignDialog from './AssignUserDialog.vue';

export default {
  components: {
    AssignDialog,
  },
  props: {
    alertRule: {
      type: Object,
      required: true,
    },
    usersBus: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      linkedUsersIds: [],
      loaded: false,
      componentSettings: this.settings,
      columns: [{
        value: 'name',
        text: t`device-group.name`,
      }, {
        value: 'actions',
        text: '',
        align: 'right',
        sortable: false,
      }],
    };
  },
  computed: {
    availableUsers() {
      return [this.user, ...this.users];
    },
    ...mapGetters({
      user: 'users/current',
    }),
    linkedUsers() {
      return this.alertRule.users.filter(
        (user) => this.linkedUsersIds.includes(user.id),
      );
    },
    unlinkedUsers() {
      return this.availableUsers.filter(
        (user) => !this.linkedUsersIds.includes(user.id),
      );
    },
  },
  watch: {
    componentSettings: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$emit('resize-masonry-item', this.$el);
        });
      },
    },
  },
  created() {
    this.usersBus.$on('unassign-user', this.onUnassign);
  },
  async mounted() {
    const { data } = await this.$http.get('users');
    if (data) {
      this.users = data;
    }
    this.linkedUsersIds = this.alertRule.users.map((u) => u.id);
    this.loaded = true;
    this.$nextTick(() => {
      this.$emit('resize-masonry-item', this.$el);
      this.$emit('detect-componet-resize', this.$el);
    });
  },
  updated() {
    this.$nextTick(() => {
      this.$emit('resize-masonry-item', this.$el);
    });
  },
  methods: {
    async link(ids) {
      const { alertRule } = this;
      this.loaded = false;
      await this.$http.patch(`/alertrules/${alertRule.id}/users/${ids.join(',')}/assign`);
      await alertRule.sync();
      this.linkedUsersIds.push(...ids);
      this.$notify.success(t`alert-rule.users.assigned-users`);
      this.loaded = true;
    },
    async unlink(ids) {
      this.loaded = false;
      const { alertRule } = this;
      await this.$http.patch(`/alertrules/${alertRule.id}/users/${ids.join(',')}/unassign`);
      await alertRule.sync();
      this.linkedUsersIds = without(ids, this.linkedUsersIds);
      this.$notify.success(t`alert-rule.users.unassigned-user`);
      this.loaded = true;
    },
    isUnlinkable(user) {
      return user.id === this.user.id
          || this.users.some((u) => u.id === user.id);
    },
    onUnassign(...id) {
      this.linkedUsersIds = without(id, this.linkedUsersIds);
    },
  },
};
</script>
