import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}},[_c(VBtn,{attrs:{"tile":"","color":"primary"},nativeOn:{"click":function($event){return _vm.create.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('device-group.create'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":_vm.loadingPage,"error":_vm.error}},[(_vm.deviceGroups.length)?_c('bkf-widget',[_c(VCardTitle,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('bkf-exporter',{ref:"exporter",attrs:{"data":_vm.deviceGroups,"columns":_vm.columns,"filename":"device-groups"}})],1)],1),_vm._v(" "),_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.deviceGroups,"item-class":_vm.rowClass,"items-per-page":_vm.settings.size,"search":_vm.search},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.settings, "size", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.settings, "size", $event)},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.stats.activeTime",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("human")(item.stats.activeTime))+"\n          ")]}},{key:"item.stats.workTime",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("human")(item.stats.workTime))+"\n          ")]}},{key:"item.stats.productivity",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.stats.productivity ? item.stats.productivity + '%' : '-')+"\n          ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}})]}}],null,true)})],1):_c('bkf-empty-state')],1):_vm._e(),_vm._v(" "),_c('router-view'),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('device-group.create')}}),_vm._v(" "),_c('bkf-page-settings',{attrs:{"settings":_vm.settings},on:{"update:settings":function($event){_vm.settings=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }