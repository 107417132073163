import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}},[_c(VBtn,{attrs:{"color":"primary","tile":""},nativeOn:{"click":function($event){return _vm.openAddDialog()}}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('device-operator.create'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":_vm.loadingPage,"error":_vm.error}},[(_vm.workers.length)?_c('bkf-widget',[_c(VCardTitle,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"mt-4",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.settings.open()}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)],1),_vm._v(" "),_c(VDataTable,{attrs:{"headers":_vm.columns,"items":_vm.workers,"item-class":_vm.rowClass,"items-per-page":_vm.settings.size,"search":_vm.search},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.device.name",fn:function(ref){
var item = ref.item;
return [(item.device)?_c('device-button',{attrs:{"device":item.device}}):_vm._e()]}},{key:"item.device",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-launch',{attrs:{"tooltip":"device.go-to-device"},on:{"click":function($event){return _vm.onDeviceClick(item.workerStatus.lastDevice.id)}}},[_vm._v("\n              "+_vm._s(item.workerStatus.lastDevice ? item.workerStatus.lastDevice.name : '-')+"\n            ")])]}},{key:"item.workerStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"my-2 d-flex"},[_c('work-time-last-active-indicator',{attrs:{"status":_vm.$deep(item, 'workerStatus.lastActiveStatus'),"last-work":_vm.$deep(item, 'workerStatus.lastWork'),"work-time":_vm.$deep(item, 'stats.workTime')}}),_vm._v(" "),_c('operator-last-active-indicator',{attrs:{"name":((_vm.$deep(item, 'firstname')) + " " + (_vm.$deep(item, 'lastname'))),"status":_vm.$deep(item, 'workerStatus.lastActiveStatus'),"last-sync":_vm.$deep(item, 'workerStatus.lastActive')}})],1)]}},{key:"item.trend",fn:function(ref){
var item = ref.item;
return [_c('work-trend-indicator',{attrs:{"value":item.trend}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-edit',{on:{"click":function($event){return _vm.openUpdateDialog(item)}}}),_vm._v(" "),_c('bkf-dialog-form-simple',{key:("workerUpdateForm" + (item.id)),ref:("workerUpdateForm" + (item.id)),attrs:{"form-url":("worker/" + (item.id) + "/edit"),"action-url":("worker/" + (item.id)),"request-method":"put","submit-text":"dialog.save","title":"device-operator.editing"},on:{"updated":_vm.onActivatePage}})]}}],null,true)})],1):_c('bkf-empty-state')],1):_vm._e(),_vm._v(" "),_c('router-view'),_vm._v(" "),_c('bkf-dialog-form-simple',{key:"addWorker",ref:"workerAddForm",attrs:{"form-url":"workers/new","action-url":"worker","request-method":"post","submit-text":"dialog.add","title":"device-operator.create"},on:{"updated":_vm.onActivatePage}}),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('device-operator.create')}}),_vm._v(" "),_c('bkf-page-settings',{ref:"pageSettings",attrs:{"settings":_vm.settings},on:{"update:settings":function($event){_vm.settings=$event},"loaded":_vm.onLoadedSettings}}),_vm._v(" "),_c('settings',{ref:"settings",on:{"submit":_vm.onSettings},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }