<template>
  <v-stepper
    v-model="stepNumber"
    alt-labels
  >
    <v-stepper-header>
      <v-stepper-step
        step="1"
        class="caption"
      >
        {{ 'reports-configuration.choose-report-type'|trans }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        step="2"
        class="caption"
      >
        {{ 'reports-configuration.report-parameters'|trans }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        step="3"
        class="caption"
      >
        {{ 'reports-configuration.choose-recipients'|trans }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        step="1"
      >
        <reports-type
          v-model="reportType"
          :reports-type="reportsType"
        />
        <div
          v-if="!reportTypeValid"
          class="error"
        >
          {{ errors.reportType[0]|trans }}
        </div>
        <v-row>
          <v-col>
            <v-btn
              tile
              color="primary"
              class="float-right ml-2"
              :disabled="reportType===''"
              @click="nextStep"
            >
              {{ 'reports-configuration.button-continue'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content
        step="2"
      >
        <div
          v-if="fetching"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
        <name
          v-model="name"
          :class="{'md-input-invalid': !nameValid}"
        />
        <intervals
          v-model="settings.intervals"
          :intervals="intervals"
          :class="{'md-input-invalid': !reportIntervalValid}"
        />
        <device-operators-group
          v-if="reportType==='device-operators-report'"
          v-model="settings.deviceOperatorsGroups"
          :device-operators-groups="deviceOperatorsGroups"
          :class="{'md-input-invalid': !deviceOperatorsValid}"
        />
        <device-groups
          v-else
          v-model="settings.deviceGroups"
          :device-groups="deviceGroups"
          :class="{'md-input-invalid': !deviceGroupsValid}"
        />
        <div
          v-if="!deviceOperatorsValid || !deviceGroupsValid"
          class="md-error error"
        >
          <template v-for="error in errors">
            {{ error[0]|trans }},
          </template>
        </div>
        <v-row>
          <v-col>
            <v-btn
              tile
              color="primary"
              class="float-right ml-2"
              :disabled="!parametersValid"
              @click="nextStep"
            >
              {{ 'reports-configuration.button-continue'|trans }}
            </v-btn>
            <v-btn
              tile
              text
              class="float-right"
              @click="previousStep"
            >
              {{ 'reports-configuration.button-back'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content
        step="3"
      >
        <users
          v-model="settings.users"
          :users="users"
          :is-report="true"
        />
        <v-row>
          <v-col>
            <v-btn
              tile
              color="primary"
              class="float-right ml-2"
              :disabled="!parametersValid"
              @click="submit"
            >
              {{ 'reports-configuration.button-finish'|trans }}
            </v-btn>
            <v-btn
              tile
              text
              class="float-right"
              @click="previousStep"
            >
              {{ 'reports-configuration.button-back'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapGetters } from 'vuex';
import ReportsType from './fields/ReportsType/ReportsType.vue';
import DeviceOperatorsGroup from './fields/DeviceOperatorsGroup/DeviceOperatorsGroup.vue';
import DeviceGroups from './fields/DeviceGroups/DeviceGroups.vue';
import Intervals from './fields/Intervals/Intervals.vue';
import Users from './fields/Users/Users.vue';
import Name from './fields/Name/Name.vue';

export default {
  components: {
    ReportsType,
    DeviceOperatorsGroup,
    DeviceGroups,
    Intervals,
    Users,
    Name,
  },
  props: {
    reportsType: {
      type: Array,
      required: false,
      default: () => [],
    },
    model: {
      required: false,
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      icon: './alert.png',
      stepNumber: 1,
      reportType: '',
      name: '',
      deviceOperatorsGroups: [],
      deviceGroups: [],
      users: [],
      intervals: [{
        id: 'day',
        name: t`el.interval.day`,
      }, {
        id: 'week',
        name: t`el.interval.week`,
      }, {
        id: 'month',
        name: t`el.interval.month`,
      },
      ],
      fetching: false,
      submiting: false,
      reportTypeValid: true,
      deviceOperatorsValid: true,
      deviceGroupsValid: true,
      reportIntervalValid: true,
      nameValid: true,
      errors: null,
      settings: {
        deviceOperatorsGroups: [],
        deviceGroups: [],
        users: [],
        intervals: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'users/current',
    }),
    parametersValid() {
      const
        {
          deviceOperatorsGroups,
          deviceGroups,
          intervals,
        } = this.settings;
      if ((deviceOperatorsGroups.length !== 0 || deviceGroups.length !== 0)
            && intervals !== null && this.name !== '') {
        return true;
      }
      return false;
    },
  },
  watch: {
    reportType() {
      if (this.reportType !== 'device-operators-report') {
        this.settings.deviceOperatorsGroups = [];
      }
      this.settings.deviceGroups = [];
      if (this.reportType !== '') {
        this.reportTypeValid = true;
      }
      this.name = '';
      this.settings.intervals = null;
    },
    name() {
      if (this.name !== '') {
        this.nameValid = true;
      }
    },
    deviceOperatorsGroups() {
      if (this.deviceOperatorsGroups !== []) {
        this.deviceOperatorsValid = true;
      }
    },
    deviceGroups() {
      if (this.deviceGroups !== []) {
        this.deviceGroupsValid = true;
      }
    },
  },
  mounted() {
    this.getDeviceOperatorsGroups();
    this.getDeviceGroups();
    this.getUsers();
  },
  methods: {
    nextStep() {
      this.stepNumber += 1;
    },
    previousStep() {
      this.stepNumber -= 1;
    },
    changeStep(stepNumber) {
      this.stepNumber = stepNumber;
      return this.stepNumber;
    },
    async getDeviceOperatorsGroups() {
      const DeviceOperatorsGroups = await this.$model('DeviceOperatorGroup');
      const { entries } = await DeviceOperatorsGroups.query({
        sort: { name: 'asc' },
      });

      this.deviceOperatorsGroups = entries.sort(
        (a, b) => a.name.localeCompare(b.name, 'pl'),
      );
    },
    async getDeviceGroups() {
      const DeviceGroup = await this.$model('DeviceGroup');
      const { entries: deviceGroups } = await DeviceGroup.query({
        sort: { name: 'asc' },
      });
      this.deviceGroups = deviceGroups;
    },
    async getUsers() {
      const { data } = await this.$http.get('users');
      if (data) {
        this.users = data;
      }
      // set current user
      const current = { name: this.user.name, id: this.user.id };
      this.settings.users.push(current);
    },
    async submit() {
      this.submiting = true;

      let groups = {};

      if (this.settings.deviceOperatorsGroups.length > 0) {
        groups = this.settings.deviceOperatorsGroups.map((item) => item.id);
      }

      if (this.settings.deviceGroups.length > 0) {
        groups = this.settings.deviceGroups.map((item) => item.id);
      }

      const payload = {
        reportType: this.reportType,
        groups,
        users: this.settings.users.map((item) => item.id),
        reportInterval: this.settings.intervals,
        name: this.name,
      };
      const response = await this.$http.post('/reportsconfigurations', payload, {
        params: { context: this.reportType },
      });
      const { data, status } = response;

      let eventName = 'submitted';

      if (status !== 201) {
        this.errors = data.errors;
        if (this.errors.reportType) {
          this.reportTypeValid = false;
        }
        if (this.errors.reportInterval) {
          this.reportIntervalValid = false;
        }
        if (this.errors.deviceOperators) {
          this.deviceOperatorsValid = false;
        }
        if (this.errors.deviceGroups) {
          this.deviceGroupsValid = false;
        }
        if (this.errors.name) {
          this.nameValid = false;
        }
        this.submiting = false;
        eventName = 'validateError';
      } else {
        this.deviceGroupsValid = true;
        this.deviceOperatorsValid = true;
        this.reportIntervalValid = true;
        this.reportTypeValid = true;
        this.nameValid = true;
      }

      const event = {
        response,
        formData: this.data,
      };

      this.$emit(eventName, event);
    },
  },
};
</script>

<style lang="stylus" scoped>
.progress
  position: absolute
  width: 100%
  height: 100%
  left: 20%
  top: -20%
  transform : translateX(-20%)
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)

.error
    color: #ff5722
.caption >>> .v-stepper__label
  text-align center
</style>
