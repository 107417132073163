<template>
  <v-tooltip
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        class="sensor-indicator"
        :class="{ active: isSensorOnline }"
        v-on="on"
      >
        fiber_manual_record
      </v-icon>
    </template>
    <span>
      {{ content }}
    </span>
  </v-tooltip>
</template>

<script>
import { distanceInWords, differenceInMinutes } from 'date-fns';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en';
import LocaleManager from '@services/locale';

export default {
  props: {
    lastSync: {
      default: null,
      required: false,
      type: String,
    },
  },
  computed: {
    content() {
      const deviceLastSync = new Date(this.lastSync);
      const locale = this.lang === 'pl' ? pl : en;

      return this.lastSync ? `${this.$trans('sensor.last-activity')}: ${distanceInWords(this.getUTCTime, deviceLastSync, { addSuffix: true, locale })}` : `${this.$trans('sensor.last-activity')}: ${this.$trans('sensor.no-data')}`;
    },
    lang() {
      const localeManager = LocaleManager.get();

      return localeManager.$language;
    },
    isSensorOnline() {
      return differenceInMinutes(this.getUTCTime, this.lastSync) < 9;
    },
    getUTCTime() {
      return new Date();
    },
  },
};
</script>

<style lang="stylus" scoped>
  .sensor-indicator
    padding-bottom 2px
    color: #ddd
    height: 14px
    width: 14px
    min-height: 14px;
    min-width: 14px
    font-size: 14px

  .sensor-indicator.active
    color: #00c853
</style>
