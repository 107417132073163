<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      >
        <v-btn
          tile
          color="primary"
          @click.native="create"
        >
          {{ 'device-group.create'|trans }}
        </v-btn>
      </portal>
      <bkf-page
        v-if="isActivePage"
        :loading="loadingPage"
        :error="error"
        class="pt-3"
      >
        <bkf-widget v-if="deviceGroups.length">
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0"
            >
              <bkf-exporter
                ref="exporter"
                :data="deviceGroups"
                :columns="columns"
                filename="device-groups"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="deviceGroups"
            :item-class="rowClass"
            :items-per-page.sync="settings.size"
            :search="search"
            @click:row="handleClick"
          >
            <template #[`item.stats.activeTime`]="{ item }">
              {{ item.stats.activeTime|human }}
            </template>
            <template #[`item.stats.workTime`]="{ item }">
              {{ item.stats.workTime|human }}
            </template>
            <template #[`item.stats.productivity`]="{ item }">
              {{ item.stats.productivity ? item.stats.productivity + '%' : '-' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <bkf-action-edit @click="edit(item)" />
            </template>
          </v-data-table>
        </bkf-widget>
        <bkf-empty-state v-else />
      </bkf-page>
      <router-view />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('device-group.create')"
      />
      <bkf-page-settings :settings.sync="settings" />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import BkfExporter from '@components/exporter';
import { mapGetters } from 'vuex';

export default {
  components: {
    BkfExporter,
  },
  mixins: [
    pageMixin,
    listMixin,
    settingsMixin,
  ],
  data() {
    return {
      loadingPage: true,
      search: '',
      settings: {
        size: 10,
      },
      deviceGroups: [],
      columns: [{
        value: 'name',
        text: t`device-group.name`,
      },
      {
        value: 'devices.length',
        text: t`device-group.devices-number`,
      },
      {
        value: 'stats.activeTime',
        text: t`device-group.activity`,
      },
      {
        value: 'stats.workTime',
        text: t`device-group.work`,
      },
      {
        value: 'stats.productivity',
        text: t`device-group.productivity`,
      },
      {
        value: 'actions',
        align: 'right',
        sortable: false,
        filterable: false,
        exportable: false,
      }],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
  },
  methods: {
    async onActivatePage() {
      await this.getData();
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'device-group', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
    async getData() {
      this.loadingPage = true;
      const params = {
        context: 'details',
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `devicegroups?${urlParams}`,
      )
        .then(
          (response) => {
            this.deviceGroups = response.data;
            this.loadingPage = false;
          },
        );
    },
  },
};
</script>
