import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}}):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":false,"error":_vm.error}},[_c('bkf-widget',[_c(VCardTitle,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c(VSpacer),_vm._v(" "),_c(VTextField,{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c(VCol,{staticClass:"pr-0 pt-7",attrs:{"cols":"auto"}},[_c('bkf-action-refresh',{on:{"click":_vm.refresh}})],1)],1),_vm._v(" "),(!_vm.siteLoading)?_c(VDataTable,{attrs:{"loading":_vm.siteLoading,"headers":_vm.columns,"items":_vm.administrationUsers,"footer-props":_vm.footerProps,"options":_vm.pagination,"server-items-length":_vm.totalItems,"single-expand":true,"expanded":_vm.expanded,"item-key":"id"},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("dateTime")(item.lastLogin))+"\n          ")]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.siteLoading)?_c(VProgressCircular,{staticClass:"mb-8",attrs:{"color":"primary","size":"60","indeterminate":""}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('router-view'),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('device.create')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }