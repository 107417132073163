<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="550"
    eager
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'profile.impersonate'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-4 flex-grow-1">
        <v-autocomplete
          v-model="user"
          :placeholder="$trans('profile.choose')"
          :clearable="true"
          :filter="findUser"
          :no-data-text="' '"
          :items="users"
          :item-value="getSelectItemValue"
        >
          <template #item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-medium"
                v-text="item.name"
              />
              <v-list-item-subtitle
                class="text--primary text-caption"
                v-text="item.username"
              />
              <v-list-item-subtitle
                class="pt-1"
                v-text="item.owner.name"
              />
            </v-list-item-content>
          </template>
          <template #selection="{ item }">
            <span v-text="item.name" />
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-spacer />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          :loading="loading"
          @click="submit"
        >
          {{ 'dialog.change'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OAuthManager from '@services/oauth';

export default {
  data() {
    return {
      showDialog: false,
      loading: true,
      users: [],
      user: null,
      hasFocus: false,
    };
  },
  methods: {
    async impersonate() {
      const { data: users } = await this.$http.get('/impersonate/users');
      this.showDialog = true;
      this.users = users;

      this.loading = false;
    },
    async submit() {
      const { user } = this;
      const oauthManager = OAuthManager.get();
      const { id } = oauthManager.getClient();
      if (user) {
        const { data } = await this.$http.get(`/impersonate/${user}?client_id=${id}`);

        oauthManager.impersonate(data);
        window.location.reload();
      }

      this.showDialog = false;
    },
    findUser(item, search) {
      const findUserName = item.name.toLowerCase().indexOf(search) > -1;
      const findUserGroupName = item.owner.name.toLowerCase().indexOf(search) > -1;
      const findEmail = item.username.toLowerCase().indexOf(search) > -1;

      return findUserName || findUserGroupName || findEmail;
    },
    focus(state) {
      this.hasFocus = state;
    },
    getSelectItemText: (item) => item.name,
    getSelectItemValue: (item) => item.id,
  },
};
</script>
