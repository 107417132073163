<template>
  <v-stepper
    v-model="step"
    alt-labels
  >
    <v-stepper-header>
      <v-stepper-step
        step="1"
        class="caption"
      >
        {{ 'alert-rule.steps.name-and-type'|trans }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        step="2"
        class="caption"
      >
        {{ 'alert-rule.steps.assigne-devices'|trans }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        step="3"
        class="caption"
      >
        {{ 'alert-rule.steps.add-condition'|trans }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        step="4"
        class="caption"
      >
        {{ 'alert-rule.steps.assigne-subordinates'|trans }}
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content
        step="1"
      >
        <div
          v-if="fetching"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
        <alert-rule
          v-if="show"
          ref="AlertRule"
          :model="model"
          :alert-rule="entry"
          @fetching="onFetching"
          @fetched="onFetched"
          @submitted="onAlertRuleSubmitted"
          @editing="alertRuleEditing"
        />
        <v-row>
          <v-col>
            <v-btn
              tile
              color="primary"
              :loading="loading"
              class="float-right ml-2"
              @click="submit"
            >
              {{ 'reports-configuration.button-continue'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content
        step="2"
      >
        <div
          v-if="!showSelects"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
        <assigned
          v-if="showSelects"
          ref="Assigned"
          :device-groups="deviceGroups"
          :devices="devices"
          :alert-rule="entry"
          @empty="emptyAssign"
          @submitted="onDevicesAssignSubmitted"
        />
        <v-row>
          <v-col>
            <v-btn
              tile
              color="primary"
              class="float-right ml-2"
              :loading="loading"
              @click="assignedSubmit"
            >
              {{ 'reports-configuration.button-continue'|trans }}
            </v-btn>
            <v-btn
              tile
              text
              class="float-right"
              @click="previousStep"
            >
              {{ 'reports-configuration.button-back'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content
        step="3"
      >
        <div
          v-if="!showConditions"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
        <template v-else>
          <condition
            v-if="showConditions && !showNoConditionsNeeded"
            ref="Condition"
            :type="type"
            :alert-rule="entry"
            :edit="editCondition"
            @submitted="onSubmittedCondition"
            @editing="conditionEditing"
          />
          <template v-else>
            <v-alert
              type="info"
              class="mt-0"
            >
              {{ 'alert-rule.no-conditions-needed'|trans }}
            </v-alert>
          </template>
          <v-row>
            <v-col>
              <v-btn
                tile
                color="primary"
                class="float-right ml-2"
                @click="conditionSubmit"
              >
                {{ 'reports-configuration.button-continue'|trans }}
              </v-btn>
              <v-btn
                tile
                text
                class="float-right"
                @click="previousStep"
              >
                {{ 'reports-configuration.button-back'|trans }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-stepper-content>
      <v-stepper-content
        step="4"
      >
        <div
          v-if="!showSelects"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
        <users
          v-if="showSelects"
          ref="Users"
          :users="users"
          :alert-rule="entry"
          :current-user="user"
        />
        <v-row>
          <v-col>
            <v-btn
              tile
              color="primary"
              class="float-right ml-2"
              @click="submitAll"
            >
              {{ 'reports-configuration.button-finish'|trans }}
            </v-btn>
            <v-btn
              tile
              text
              class="float-right"
              @click="previousStep"
            >
              {{ 'reports-configuration.button-back'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import conditionDescription from '@mixins/misc/conditionDescription';
import { mapGetters } from 'vuex';
import AlertRule from './steps/AlertRule.vue';
import Condition from './steps/Condition.vue';
import Assigned from './steps/Assigned.vue';
import Users from './steps/Users.vue';

// States as events
const events = {
  fetching: 'fetching',
  fetched: 'fetched',
  fetchError: 'fetchError',
  submitting: 'creating',
  submitted: 'created',
  submitError: 'createError',
  validateError: 'validateError',
};

export default {
  components: {
    AlertRule,
    Condition,
    Assigned,
    Users,
  },
  mixins: [conditionDescription],
  props: {
    model: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      id: null,
      alertRule: null,
      entry: null,
      show: false,
      fetching: true,
      submitting: false,
      step: 1,
      options: {},
      showConditions: false,
      showNoConditionsNeeded: false,
      showUsers: false,
      showAssigned: false,
      deviceGroups: [],
      devices: [],
      users: [],
      stepper: null,
      alertRuleSubmitted: false,
      assignSubmitted: false,
      conditionSubmitted: false,
      conditionForm: true,
      showSelects: false,
      editCondition: false,
      isEmpty: true,
      loading: false,
      type: '',
    };
  },
  computed: {
    ...mapGetters({
      user: 'users/current',
    }),
  },
  mounted() {
    this.getDeviceGroups();
    this.getDevices();
    this.getUsers();
    this.stepper = this.$refs.stepper;
    this.show = true;
  },
  methods: {
    emptyAssign(payload) {
      this.isEmpty = payload;
    },
    changeStep() {
      const stepNumber = this.step;

      if (stepNumber === 0) {
        this.show = true;
      }
      if (stepNumber === 2 && this.conditionSubmitted) {
        this.showConditions = true;
      }
      if (stepNumber === 1 && !this.alertRuleSubmitted) {
        this.submit();
      }
      if (stepNumber === 2 && !this.assignSubmitted) {
        this.assignedSubmit();
      }
      if (stepNumber === 3 && !this.conditionSubmitted && !this.showNoConditionsNeeded) {
        this.conditionSubmit();
      }
    },
    nextStep() {
      this.changeStep();
      this.step += 1;
    },
    previousStep() {
      // this.changeStep();
      this.step -= 1;
    },
    onFetching() {
      this.fetching = true;
      this.$emit(events.fetching);
    },
    onFetched() {
      this.fetching = false;
      this.$emit(events.fetched);
    },
    onFetchError(payload) {
      this.fetching = false;
      this.show = false;
      this.$emit(events.fetchError, payload);
    },
    onSubmitting() {
      this.submitting = true;
    },
    async onAlertRuleSubmitted(payload) {
      const { response } = payload;
      this.type = payload.formData.type;
      this.alertRuleSubmitted = true;
      this.id = response.data.id;
      this.show = false;
      this.getAssignForm();
      this.step = 2;
    },
    async onDevicesAssignSubmitted() {
      this.assignSubmitted = true;
      this.getConditionForm();
      this.step = 3;
    },
    async onSubmitError(payload) {
      this.$emit(events.submitError, payload);
    },
    onValidateError() {
      this.submitting = false;
      this.$emit(events.validateError);
    },
    submitAll() {
      this.usersSubmit();
      const { id } = this;
      this.$router.push({ name: 'alert-rule', params: { id } });
      this.$notify.success(t`alert-rule.created`);
      this.$emit('submitted');
    },
    async submit() {
      this.loading = true;
      await this.$refs.AlertRule.$refs.form.submit();
      this.loading = false;
    },
    async conditionSubmit() {
      this.loading = true;
      if (this.$refs.Condition) {
        this.$refs.Condition.$refs.form.submit();
      } else {
        this.step += 1;
      }
      this.loading = false;
    },
    async assignedSubmit() {
      this.loading = true;
      await this.$refs.Assigned.link();
      this.loading = false;
    },
    usersSubmit() {
      this.$refs.Users.link();
    },
    async onSubmittedCondition() {
      this.conditionSubmitted = true;
      this.editCondition = true;
      this.saveDefaultDescription();
      this.step = 4;
    },
    async getConditionForm() {
      this.showConditions = false;
      this.model = await this.$model('AlertRule');
      this.entry = await this.model.get({
        id: this.id,
      });
      this.alertRule = this.entry.$data;

      if (this.alertRule.type === 'authentication' || this.alertRule.type === 'work without operator') {
        this.showNoConditionsNeeded = true;
      }

      this.conditionForm = false;
      this.showConditions = true;
    },
    async getAssignForm() {
      this.showSelects = false;
      this.model = await this.$model('AlertRule');
      this.entry = await this.model.get({
        id: this.id,
      });
      this.alertRule = this.entry.$data;
      this.showSelects = true;
    },
    async getAlertRule() {
      this.model = await this.$model('AlertRule');
      this.entry = await this.model.get({
        id: this.id,
      });
      this.alertRule = this.entry.$data;
    },
    async getDeviceGroups() {
      const model = await this.$model('DeviceGroup', { contexts: ['default'] });
      const { entries } = await model.query({
        sort: { name: 'ASC' },
      });

      this.deviceGroups = entries.map((entry) => entry.$data);
    },
    async getDevices() {
      const model = await this.$model('Device', { contexts: ['default'] });
      const { entries } = await model.query({
        sort: { name: 'ASC' },
      });

      this.devices = entries.map((entry) => entry.$data);
    },
    async getUsers() {
      const { data } = await this.$http.get('users');
      if (data) {
        this.users = data;
      }
    },
    alertRuleEditing() {
      this.alertRuleSubmitted = false;
    },
    conditionEditing() {
      this.conditionSubmitted = false;
    },
    async getAlarmRuleToEdit() {
      const model = this.entry.$model;
      const pluralized = model.$pluralized;
      // Get AlarmRule
      const params = {
        id: this.entry.id,
        action: 'edit',
      };
      const payload = {};
      const responseEdit = await model.$connector.get(pluralized, params);
      const { data, status } = responseEdit;
      if (status === 200) {
        payload.name = data.data.name;
        payload.importance = data.data.importance;
      }
      return payload;
    },
    async saveDefaultDescription() {
      if (this.entry.description === null) {
        const model = this.entry.$model;
        const pluralized = model.$pluralized;
        const link = `/${pluralized}/${this.entry.id}`;

        const payload = await this.getAlarmRuleToEdit();
        const description = await this.createDescription(this.alertRule,
          this.entry.conditions[0]);

        payload.description = description;
        const response = await model.$connector.$client.put(link, payload);
        const { data, status } = response;

        if (status === 400 && data.errors) {
          this.errors = data.errors;

          if (this.errors.rootErrors) {
            this.errors.rootErrors.forEach((error) => {
              this.$notify.error(error);
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.progress
  position: absolute
  width: 100%
  height: 100%
  left: 20%
  top: -20%
  transform : translateX(-20%)
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
.form
  margin-bottom 20px
.caption >>> .v-stepper__label
  text-align center
</style>
